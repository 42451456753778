import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';

export const route = '/boca/record';
export const role = 'IT';

const RecordCleaning = () => {
    const [printers, setPrinters] = useState([]);
    const [selectedPrinter, setSelectedPrinter] = useState('');
    const [cleanedBy, setCleanedBy] = useState('');
    const [feedback, setFeedback] = useState('');

    useEffect(() => {
        const fetchPrinters = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/boca/printers`);
                const data = await response.json();
                setPrinters(data);
            } catch (error) {
                console.error('Error fetching printers:', error);
            }
        };

        fetchPrinters();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const weekStart = new Date();
        weekStart.setDate(weekStart.getDate() - weekStart.getDay());

        const record = {
            printerId: selectedPrinter,
            cleanedBy,
            weekStart
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/boca/clean`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(record)
            });

            if (response.ok) {
                setFeedback('Cleaning record added successfully.');
            } else {
                setFeedback('Failed to add cleaning record.');
            }
        } catch (error) {
            setFeedback('Error adding cleaning record.');
            console.error('Error adding cleaning record:', error);
        }
    };

    return (
        <Container className="my-5">
            <h1 className="text-center mb-5">Record Boca Printer Cleaning</h1>
            {feedback && <Alert variant={feedback.includes('successfully') ? 'success' : 'danger'}>{feedback}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="printerSelect" className="mb-3">
                    <Form.Label>Select Printer</Form.Label>
                    <Form.Control as="select" value={selectedPrinter} onChange={(e) => setSelectedPrinter(e.target.value)} required>
                        <option value="">Select a printer...</option>
                        {printers.map(printer => (
                            <option key={printer._id} value={printer._id}>{printer.name}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="cleanedBy" className="mb-3">
                    <Form.Label>Cleaned By</Form.Label>
                    <Form.Control type="text" value={cleanedBy} onChange={(e) => setCleanedBy(e.target.value)} required />
                </Form.Group>
                <Button variant="primary" type="submit">Record Cleaning</Button>
            </Form>
        </Container>
    );
};

export default RecordCleaning;