import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const route = "/it/spare/create";
export const role = "IT";

function CreateSpareDeployment() {
    const [spareData, setSpareData] = useState({
        originalReader: '',
        spareReader: '',
        swapDate: '',
        reason: ''
    });
    const [devices, setDevices] = useState([]);
    const [spares, setSpares] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/devices/`)
            .then(res => res.json())
            .then(data => {
                const ticketReaders = data.filter(device => (device.type === 'Ticket Reader' && device.isSpare === false));
                const spares = data.filter(device => device.isSpare === true);

                setSpares(spares)
                setDevices(ticketReaders);
            })
            .catch(err => console.error("Failed to load devices", err));
    }, []);

    const handleChange = (e) => {
        setSpareData({ ...spareData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/spare/deployments/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(spareData)
            });

            if (!response.ok) {
                throw new Error('Failed to add spare deployment');
            }

            alert('Spare deployment added successfully!');
            navigate('/it/spare/list');
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Failed to add spare deployment due to server error.')
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-4">Add New Spare Deployment</h2>
            <form onSubmit={handleSubmit}>
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
                <div className="mb-3">
                    <label htmlFor="originalReader" className="form-label">Original Reader</label>
                    <select name="originalReader" value={spareData.originalReader} onChange={handleChange} className="form-select">
                        <option value="">Select Original Reader</option>
                        {devices.map(device => <option key={device._id} value={device._id}>{device.name}</option>)}
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="spareReader" className="form-label">Spare</label>
                    <select name="spareReader" value={spareData.spareReader} onChange={handleChange} className="form-select">
                        <option value="">Select Spare Reader</option>
                        {spares.map(spare => <option key={spare._id} value={spare._id}>{spare.name}</option>)}
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="swapDate" className="form-label">Date and Time of Swap</label>
                    <input type="datetime-local" className="form-control" name="swapDate" value={spareData.swapDate} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="reason" className="form-label">Reason</label>
                    <input type="text" className="form-control" name="reason" value={spareData.reason} onChange={handleChange} placeholder="Reason for spare" />
                </div>
                <button type="submit" className="btn btn-primary">Add Spare Deployment</button>
            </form>
        </div>
    );
}

export default CreateSpareDeployment;