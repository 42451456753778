import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';

const importAllPages = (r) => {
  return r.keys().map(r);
};

const pages = importAllPages(require.context('./pages', true, /\.jsx$/));

const getRoutesFromPages = (pages) => {
  return pages.map((page) => {
    const PageComponent = page.default;
    const route = page.route;
    const role = page.role;
    return { PageComponent, route, role };
  });
};

const routes = getRoutesFromPages(pages);

function App() {
  return (
    <div className="app-container d-flex flex-column min-vh-100" id="root">
      <Navbar />
      <div className="content-wrap flex-grow-1 content">
        <Routes>
          {routes.map(({ PageComponent, route, role }) => {
            if (role) {
              return (
                <Route key={route} element={<ProtectedRoute role={role} />}>
                  <Route path={route} element={<PageComponent />} />
                </Route>
              );
            }
            return <Route key={route} path={route} element={<PageComponent />} />;
          })}
          {/* Fallback route to handle unknown URLs */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
