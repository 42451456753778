import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

export const route = "/it/spare/edit/:spareId";
export const role = "IT";

function EditSpareDeployment() {
    const { spareId } = useParams();
    const [spareData, setSpareData] = useState({
        originalReader: '',
        spareReader: '',
        swapDate: '',
        reason: '',
        restoreDate: ''
    });
    const [devices, setDevices] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSpareDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/spare/deployments/${spareId}`);
                if (response.ok) {
                    const data = await response.json();
                    setSpareData({
                        originalReader: data.originalReader._id,
                        spareReader: data.spareReader._id,
                        swapDate: data.swapDate ? new Date(data.swapDate).toISOString().slice(0, 16) : '',
                        reason: data.reason,
                        restoreDate: data.restoreDate ? new Date(data.restoreDate).toISOString().slice(0, 16) : ''
                    });
                } else {
                    console.error('Failed to fetch spare deployment details');
                }
            } catch (error) {
                console.error('Error fetching spare deployment details:', error);
            }
        };

        const fetchDevices = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/`);
                if (response.ok) {
                    const data = await response.json();
                    setDevices(data);
                } else {
                    console.error('Failed to fetch devices');
                }
            } catch (error) {
                console.error('Error fetching devices:', error);
            }
        };

        fetchSpareDetails();
        fetchDevices();
    }, [spareId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSpareData({ ...spareData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/spare/deployments/${spareId}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(spareData)
            });

            if (response.ok) {
                navigate('/it/spare/list'); // Redirect to spare list after successful update
            } else {
                alert('Failed to update spare deployment');
            }
        } catch (error) {
            console.error('Error updating spare deployment:', error);
        }
    };

    return (
        <div className="container mt-3">
            <h2>Edit Spare Deployment</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="originalReader" className="form-label">Original Reader</label>
                    <select
                        name="originalReader"
                        value={spareData.originalReader}
                        onChange={handleChange}
                        className="form-select"
                    >
                        <option value="">Select Original Reader</option>
                        {devices.filter(device => device.type === 'Ticket Reader').map(device => (
                            <option key={device._id} value={device._id}>
                                {device.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="spareReader" className="form-label">Spare Reader</label>
                    <select
                        name="spareReader"
                        value={spareData.spareReader}
                        onChange={handleChange}
                        className="form-select"
                    >
                        <option value="">Select Spare Reader</option>
                        {devices.filter(device => device.type === 'Ticket Reader').map(device => (
                            <option key={device._id} value={device._id}>
                                {device.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-3">
                    <label className="form-label">Swap Date</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        name="swapDate"
                        value={spareData.swapDate}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Reason</label>
                    <input
                        type="text"
                        className="form-control"
                        name="reason"
                        value={spareData.reason}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Restore Date</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        name="restoreDate"
                        value={spareData.restoreDate}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit" className="btn mb-5 btn-primary">Update Spare Deployment</button>
            </form>
        </div>
    );
}

export default EditSpareDeployment;