import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Table } from 'react-bootstrap';
import styles from '../../CSS/InventoryDashboard.module.css';

export const route = '/inventory';
export const role = 'IT';

const InventoryDashboard = () => {
    const [inventoryItems, setInventoryItems] = useState([]);

    useEffect(() => {
        // Fetch inventory items from API
        const fetchInventoryItems = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/inventory`);
            const data = await response.json();
            setInventoryItems(data);
        };

        fetchInventoryItems();
    }, []);

    // Group inventory items by category
    const groupedItems = inventoryItems.reduce((acc, item) => {
        if (!acc[item.category]) {
            acc[item.category] = [];
        }
        acc[item.category].push(item);
        return acc;
    }, {});

    return (
        <Container className="mt-5">
            <h1 className="text-center mb-5">Inventory Dashboard</h1>
            {Object.keys(groupedItems).map((category, categoryIndex) => (
                <div key={categoryIndex} className="mb-5">
                    <h2 className="mb-4">{category}</h2>
                    <Table striped bordered hover className="table-responsive">
                        <thead>
                            <tr>
                                <th className={styles.headerText}>#</th>
                                <th className={styles.headerText}>Name</th>
                                <th className={styles.headerText}>Quantity</th>
                                <th className={styles.headerText}>Location</th>
                                <th className={styles.headerText}>Custom Attributes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupedItems[category].map((item, index) => (
                                <tr key={item._id}>
                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.location}</td>
                                    <td>{item.customAttributes.map(attr => `${attr.name}: ${attr.value}`).join(', ')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            ))}
            <div className="text-center mt-5">
                <Link to="/inventory/manage" className={`btn ${styles.btnPrimary}`}>Manage Inventory</Link>
                {/* <Link to="/inventory/analytics" className={`btn ${styles.btnSecondary} ms-3`}>View Analytics</Link> */}
            </div>
        </Container>
    );
};

export default InventoryDashboard;