import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Table, Spinner, Alert } from 'react-bootstrap';

export const route = "/officer/email-list";
export const role = "Officer";

const EmailList = () => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newEmail, setNewEmail] = useState('');
  const [editingEmail, setEditingEmail] = useState(null);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/emails`);
        const data = await response.json();
        setEmails(data);
      } catch (error) {
        setError('Failed to fetch emails');
      } finally {
        setLoading(false);
      }
    };

    fetchEmails();
  }, []);

  const handleAddEmail = async () => {
    if (newEmail.trim() === '') return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/emails`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newEmail }),
      });

      if (response.ok) {
        const newEmailData = await response.json();
        setEmails([...emails, newEmailData]);
        setNewEmail('');
      }
    } catch (error) {
      setError('Failed to add email');
    }
  };

  const handleDeleteEmail = async (id) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/emails/${id}`, {
        method: 'DELETE',
      });

      setEmails(emails.filter(email => email._id !== id));
    } catch (error) {
      setError('Failed to delete email');
    }
  };

  const handleEditEmail = async (id) => {
    if (editingEmail.trim() === '') return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/emails/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: editingEmail }),
      });

      if (response.ok) {
        const updatedEmail = await response.json();
        setEmails(emails.map(email => (email._id === id ? updatedEmail : email)));
        setEditingEmail(null);
      }
    } catch (error) {
      setError('Failed to edit email');
    }
  };

  if (loading) return <Spinner animation="border" variant="primary" />;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <Container>
      <h1 className="mt-4">Email List</h1>
      <Form className="mb-4">
        <Form.Group controlId="formNewEmail">
          <Form.Label>New Email</Form.Label>
          <Form.Control
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            placeholder="Enter new email"
          />
        </Form.Group>
        <Button variant="primary" className="mt-2" onClick={handleAddEmail}>
          Add Email
        </Button>
      </Form>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {emails.map((email) => (
            <tr key={email._id}>
              <td>
                {editingEmail === email._id ? (
                  <Form.Control
                    type="email"
                    defaultValue={email.email}
                    onChange={(e) => setEditingEmail(e.target.value)}
                  />
                ) : (
                  email.email
                )}
              </td>
              <td>
                {editingEmail === email._id ? (
                  <Button
                    variant="success"
                    onClick={() => handleEditEmail(email._id)}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="warning"
                    onClick={() => setEditingEmail(email._id)}
                  >
                    Edit
                  </Button>
                )}
                <Button
                  variant="danger"
                  className="ms-2"
                  onClick={() => handleDeleteEmail(email._id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default EmailList;