import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Button, Modal, Form, Card, Dropdown } from 'react-bootstrap';

export const route = "/screens";
export const role = "IT";

const ScreensPage = () => {
    const [screens, setScreens] = useState([]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [screenToDelete, setScreenToDelete] = useState(null);
    const [newScreenTitle, setNewScreenTitle] = useState('');
    const [newScreenDescription, setNewScreenDescription] = useState('');
    const [newScreenOrientation, setNewScreenOrientation] = useState('landscape');
    const [newScreenPairingCode, setNewScreenPairingCode] = useState('');
    const [accessToken, setAccessToken] = useState('');

    // Function to authenticate with the AbleSign API
    const authenticate = useCallback(async () => {
        try {
            const response = await axios.post('https://api.ablesign.tv/api/auth/signin', {
                username: 'aaron.long@moreyspiers.com',
                password: 'MVLAELhvl$729'
            });
            setAccessToken(response.data.accessToken);
        } catch (error) {
            console.error('Error during authentication:', error.message);
        }
    }, []);

    // Function to fetch screens
    const fetchScreens = useCallback(async () => {
        try {
            const response = await axios.get('https://api.ablesign.tv/api/screens', {
                headers: {
                    'X-Access-Token': accessToken
                },
                params: {
                    limit: 999,
                    sortBy: 'creation_date_desc'
                }
            });
            setScreens(response.data.screens);
        } catch (error) {
            console.error('Error fetching screens:', error.message);
        }
    }, [accessToken]);

    // Function to handle screen creation
    const handleCreateScreen = async () => {
        try {
            const response = await axios.post('https://api.ablesign.tv/api/screens', {
                registrationCode: newScreenPairingCode,
                title: newScreenTitle,
                description: newScreenDescription,
                orientation: newScreenOrientation
            }, {
                headers: {
                    'X-Access-Token': accessToken
                }
            });
            setScreens([...screens, response.data.screen]); // Update the list with the new screen
            setShowCreateModal(false); // Close the modal
            setNewScreenTitle(''); // Reset form fields
            setNewScreenDescription('');
            setNewScreenOrientation('landscape');
            setNewScreenPairingCode('');
        } catch (error) {
            console.error('Error creating screen:', error.message);
        }
    };

    // Function to handle screen deletion
    const handleDeleteScreen = async () => {
        if (screenToDelete) {
            try {
                await axios.delete(`https://api.ablesign.tv/api/screens/${screenToDelete.id}`, {
                    headers: {
                        'X-Access-Token': accessToken
                    }
                });
                setScreens(screens.filter(screen => screen.id !== screenToDelete.id));
                setShowDeleteModal(false);
            } catch (error) {
                console.error('Error deleting screen:', error.message);
            }
        }
    };

    // Show confirmation modal before deleting
    const confirmDeleteScreen = (screen) => {
        setScreenToDelete(screen);
        setShowDeleteModal(true);
    };

    useEffect(() => {
        authenticate().then(() => {
            if (accessToken) {
                fetchScreens();
            }
        });
    }, [authenticate, accessToken, fetchScreens]);

    return (
        <div className="container">
            <h1>Screens</h1>
            <Button variant="primary" onClick={() => setShowCreateModal(true)}>Create Screen</Button>
            <div className="screens-list mt-4 row">
                {screens.map(screen => (
                    <div key={screen.id} className="col-md-4 mb-4">
                        <Card className='card shadow-sm'>
                            <Card.Body>
                                <Card.Title>{screen.title}</Card.Title>
                                <Card.Text>
                                    {screen.description || 'No description provided.'}
                                </Card.Text>
                                <Card.Text>
                                    <small>Orientation: {screen.orientation}</small>
                                </Card.Text>
                                <Dropdown align="end">
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                        &#x22EE;
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => confirmDeleteScreen(screen)}>
                                            Delete
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>

            {/* Create Screen Modal */}
            <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Screen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="screenPairingCode">
                            <Form.Label>Pairing Code</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter pairing code"
                                value={newScreenPairingCode}
                                onChange={(e) => setNewScreenPairingCode(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="screenTitle" className="mt-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter screen title"
                                value={newScreenTitle}
                                onChange={(e) => setNewScreenTitle(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="screenDescription" className="mt-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter screen description"
                                value={newScreenDescription}
                                onChange={(e) => setNewScreenDescription(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="screenOrientation" className="mt-3">
                            <Form.Label>Orientation</Form.Label>
                            <Form.Select
                                value={newScreenOrientation}
                                onChange={(e) => setNewScreenOrientation(e.target.value)}
                            >
                                <option value="landscape">Landscape</option>
                                <option value="portrait">Portrait</option>
                                <option value="reverse_landscape">Reverse Landscape</option>
                                <option value="reverse_portrait">Reverse Portrait</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCreateScreen}>
                        Create Screen
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the screen titled "{screenToDelete?.title}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteScreen}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ScreensPage;