import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import '../../CSS/ManageInventory.css';

export const route = '/inventory/edit/:id';
export const role = 'IT';

const EditInventoryItem = () => {
    const { id } = useParams();
    const [item, setItem] = useState({
        name: '',
        category: '',
        quantity: 0,
        location: '',
        customAttributes: []
    });

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch inventory item from API by ID
        const fetchInventoryItem = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/inventory/${id}`);
            const data = await response.json();
            setItem(data);
        };

        fetchInventoryItem();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setItem({ ...item, [name]: value });
    };

    const handleCustomAttributeChange = (index, e) => {
        const { name, value } = e.target;
        const attributes = [...item.customAttributes];
        attributes[index][name] = value;
        setItem({ ...item, customAttributes: attributes });
    };

    const handleAddCustomAttribute = () => {
        setItem({ ...item, customAttributes: [...item.customAttributes, { name: '', value: '' }] });
    };

    const handleRemoveCustomAttribute = (index) => {
        const attributes = [...item.customAttributes];
        attributes.splice(index, 1);
        setItem({ ...item, customAttributes: attributes });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/inventory/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(item)
        });

        if (response.ok) {
            navigate('/inventory');
        }
    };

    return (
        <Container className="mt-5">
            <h1 className="text-center mb-5">Edit Inventory Item</h1>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col md={6}>
                        <Form.Group controlId="itemName">
                            <Form.Label>Item Name</Form.Label>
                            <Form.Control type="text" name="name" value={item.name} onChange={handleInputChange} required />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="itemCategory">
                            <Form.Label>Category</Form.Label>
                            <Form.Control type="text" name="category" value={item.category} onChange={handleInputChange} required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <Form.Group controlId="itemQuantity">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control type="number" name="quantity" value={item.quantity} onChange={handleInputChange} required />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="itemLocation">
                            <Form.Label>Location</Form.Label>
                            <Form.Control type="text" name="location" value={item.location} onChange={handleInputChange} required />
                        </Form.Group>
                    </Col>
                </Row>
                <h4>Custom Attributes</h4>
                {item.customAttributes.map((attr, index) => (
                    <Row className="mb-3" key={index}>
                        <Col md={5}>
                            <Form.Group controlId={`customAttributeName${index}`}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={attr.name}
                                    onChange={(e) => handleCustomAttributeChange(index, e)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={5}>
                            <Form.Group controlId={`customAttributeValue${index}`}>
                                <Form.Label>Value</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="value"
                                    value={attr.value}
                                    onChange={(e) => handleCustomAttributeChange(index, e)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={2} className="d-flex align-items-end">
                            <Button variant="danger" onClick={() => handleRemoveCustomAttribute(index)}>Remove</Button>
                        </Col>
                    </Row>
                ))}
                <Button variant="secondary" onClick={handleAddCustomAttribute}>Add Custom Attribute</Button>
                <Button variant="primary" type="submit" className="mt-3">Update Item</Button>
            </Form>
        </Container>
    );
};

export default EditInventoryItem;