import React, { useEffect, useState } from 'react';
import { Container, Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../../CSS/ManageInventory.css';

export const route = '/inventory/manage';
export const role = 'IT';

const ManageInventory = () => {
    const [inventoryItems, setInventoryItems] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch inventory items from API
        const fetchInventoryItems = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/inventory`);
            const data = await response.json();
            setInventoryItems(data);
        };

        fetchInventoryItems();
    }, []);

    const handleDelete = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/inventory/${id}`, {
            method: 'DELETE'
        });

        if (response.ok) {
            setInventoryItems(inventoryItems.filter(item => item._id !== id));
        }
    };

    // Group inventory items by category
    const groupedItems = inventoryItems.reduce((acc, item) => {
        if (!acc[item.category]) {
            acc[item.category] = [];
        }
        acc[item.category].push(item);
        return acc;
    }, {});

    return (
        <Container className="mt-5">
            <h1 className="text-center mb-5">Manage Inventory</h1>
            <Button variant="primary" onClick={() => navigate('/inventory/create')} className="mb-3">Add New Item</Button>
            {Object.keys(groupedItems).map((category, categoryIndex) => (
                <div key={categoryIndex} className="mb-5">
                    <h2 className="mb-4">{category}</h2>
                    <Table striped bordered hover className="table-responsive">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Quantity</th>
                                <th>Location</th>
                                <th>Custom Attributes</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupedItems[category].map((item, index) => (
                                <tr key={item._id}>
                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.location}</td>
                                    <td>{item.customAttributes.map(attr => `${attr.name}: ${attr.value}`).join(', ')}</td>
                                    <td>
                                        <Button variant="info" onClick={() => navigate(`/inventory/edit/${item._id}`)} className="me-2">Edit</Button>
                                        <Button variant="danger" onClick={() => handleDelete(item._id)}>Delete</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            ))}
        </Container>
    );
};

export default ManageInventory;