import React, { useState, useEffect, useCallback } from 'react';
import { Form, Spinner, Table, Alert, Row, Col, Pagination, Modal, Button } from 'react-bootstrap';
import { format, subDays, subMonths, startOfWeek, startOfMonth, addDays } from 'date-fns';
import axios from 'axios';
import * as XLSX from 'xlsx';

export const route = "/mimo/transactions";
export const role = "MIMO";

function TransactionList() {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [dateFilter, setDateFilter] = useState('All');
    const [customStartDate, setCustomStartDate] = useState('');
    const [customEndDate, setCustomEndDate] = useState('');
    const [username, setUsername] = useState('');
    const [terminalId, setTerminalId] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [registerName, setRegisterName] = useState('');
    const [sortField, setSortField] = useState('TRANS_ID');
    const [sortOrder, setSortOrder] = useState('DESC');
    const [showModal, setShowModal] = useState(false);
    const [showTransactionModal, setShowTransactionModal] = useState(false);
    const [entries, setEntries] = useState('all');
    const [groupByRegister, setGroupByRegister] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const limit = 10;

    const denominations = [
        { label: "$0.01", field: "0_01", value: 0.01 },
        { label: "$0.05", field: "0_05", value: 0.05 },
        { label: "$0.10", field: "0_10", value: 0.10 },
        { label: "$0.25", field: "0_25", value: 0.25 },
        { label: "$0.50", field: "0_50", value: 0.50 },
        { label: "$1.00", field: "1_00", value: 1.00 },
        { label: "$1", field: "1", value: 1.00 },
        { label: "$2", field: "2", value: 2.00 },
        { label: "$5", field: "5", value: 5.00 },
        { label: "$10", field: "10", value: 10.00 },
        { label: "$20", field: "20", value: 20.00 },
        { label: "$50", field: "50", value: 50.00 },
        { label: "$100", field: "100", value: 100.00 },
    ];

    const handleExportClick = () => {
        if (dateFilter === 'All') {
            setDateFilter('today');
        }
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleshowTransactionModal = (transaction) => {
        setSelectedTransaction(transaction);
        setShowTransactionModal(true);
    };

    const handleTransactionModalClose = () => {
        setShowTransactionModal(false);
        setSelectedTransaction(null);
    };

    const handleExport = async () => {
        try {
            let startDate, endDate;
            const today = new Date();
            switch (dateFilter) {
                case 'today':
                    startDate = endDate = format(today, 'yyyy-MM-dd');
                    break;
                case 'yesterday':
                    startDate = endDate = format(subDays(today, 1), 'yyyy-MM-dd');
                    break;
                case 'custom':
                    startDate = customStartDate;
                    endDate = customEndDate;
                    if (!startDate || !endDate) {
                        throw new Error('Custom date range requires both start and end dates');
                    }
                    break;
                default:
                    startDate = '1970-01-01';
                    endDate = format(new Date(), 'yyyy-MM-dd');
            }
    
            const startDateTime = `${startDate} 00:00:00`;
            const endDateTime = `${endDate} 23:59:59`;
    
            const url = `${process.env.REACT_APP_SNOWFLAKE_URL}/mimo/transactions/1/${entries === 'all' ? '99999999999999999' : entries}/${startDateTime}/${endDateTime}?username=${username}&terminalId=${terminalId}&transactionId=${transactionId}&registerName=${registerName}&sortField=${sortField}&sortOrder=${sortOrder}`;
    
            const response = await axios.get(url);
            let data = response.data;
    
            if (!data || data.length === 0) {
                throw new Error('No data available for export');
            }
    
            // Define headers explicitly in the desired order
            const headers = [
                'User ID', 'User Name', 'Register Name', 'Terminal ID', 'Date and Time',
                'Transaction ID', 'Transaction Name', 'Transaction Total',
                '0.01', '0.05', '0.10', '0.25', '0.50', '1.00C',
                '1', '2', '5', '10', '20', '50', '100'
            ];
    
            // Map data to match the headers exactly, with denominations as formatted dollar amounts or blank if zero
            const formatAmount = (amount, multiplier) => amount && amount !== 0 ? `$${(amount * multiplier).toFixed(2)}` : '';
    
            const orderedData = data.map(transaction => [
                transaction.USER_ID,
                transaction.USER_NAME,
                transaction.REGISTER_NAME,
                transaction.TERMINAL_ID,
                transaction.TRANSACTION_DATETIME,
                transaction.TRANS_ID,
                transaction.TRANS_NAME,
                `$${transaction.TRANS_TOTAL.toFixed(2)}`, // Format with dollar sign
                formatAmount(transaction['0_01'], 0.01),
                formatAmount(transaction['0_05'], 0.05),
                formatAmount(transaction['0_10'], 0.10),
                formatAmount(transaction['0_25'], 0.25),
                formatAmount(transaction['0_50'], 0.50),
                formatAmount(transaction['1_00'], 1.00),
                formatAmount(transaction['1'], 1.00),
                formatAmount(transaction['2'], 2.00),
                formatAmount(transaction['5'], 5.00),
                formatAmount(transaction['10'], 10.00),
                formatAmount(transaction['20'], 20.00),
                formatAmount(transaction['50'], 50.00),
                formatAmount(transaction['100'], 100.00)
            ]);
    
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet([]);
    
            const adjustedStartDate = format(addDays(new Date(startDate), 1), 'MM/dd/yyyy');
            const adjustedEndDate = format(addDays(new Date(endDate), 1), 'MM/dd/yyyy');
    
            // Add metadata to the worksheet
            XLSX.utils.sheet_add_aoa(worksheet, [
                [`Transaction Log`],
                [`Date Created: ${format(new Date(), 'MM/dd/yyyy')}`],
                [`Date Searched: ${adjustedStartDate} - ${adjustedEndDate}`],
                []
            ], { origin: 'A1' });
    
            // Set headers on line 5, leaving row 4 empty
            XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
    
            // Add the orderedData starting on line 6
            XLSX.utils.sheet_add_aoa(worksheet, orderedData, { origin: 'A6' });
    
            // Set column widths
            worksheet['!cols'] = Array(headers.length).fill({ width: 15 });
    
            // Apply red font color for negative totals in the 'Transaction Total' column (index 7 after metadata rows)
            orderedData.forEach((row, index) => {
                const totalCell = XLSX.utils.encode_cell({ r: index + 5, c: 7 });
                const totalValue = parseFloat(row[7].replace('$', ''));
    
                if (totalValue < 0) {
                    worksheet[totalCell].s = {
                        font: { color: { rgb: "FF0000" } }
                    };
                }
            });
    
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');
            XLSX.writeFile(workbook, 'transaction_log.xlsx');
    
        } catch (error) {
            console.error('Error exporting transactions:', error);
            alert('Error exporting transactions: ' + error.message);
        }
    
        handleModalClose();
    };    

    const fetchTransactions = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            let startDate, endDate;
            const today = new Date();
            switch (dateFilter) {
                case 'today':
                    startDate = endDate = format(today, 'yyyy-MM-dd');
                    break;
                case 'yesterday':
                    startDate = endDate = format(subDays(today, 1), 'yyyy-MM-dd');
                    break;
                case 'currentWeek':
                    startDate = format(startOfWeek(today), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'currentMonth':
                    startDate = format(startOfMonth(today), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last3Days':
                    startDate = format(subDays(today, 3), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last7Days':
                    startDate = format(subDays(today, 7), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last15Days':
                    startDate = format(subDays(today, 15), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last30Days':
                    startDate = format(subDays(today, 30), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last3Months':
                    startDate = format(subMonths(today, 3), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'custom':
                    startDate = customStartDate;
                    endDate = customEndDate;
                    if (!startDate || !endDate) {
                        setError('Custom date range requires both start and end dates');
                        setLoading(false);
                        return;
                    }
                    break;
                default:
                    startDate = '1970-01-01';
                    endDate = format(new Date(), 'yyyy-MM-dd');
            }

            const startDateTime = `${startDate} 00:00:00`;
            const endDateTime = `${endDate} 23:59:59`;

            const fetchLimit = groupByRegister ? '99999999999999999' : limit;
            const orderByField = groupByRegister ? 'REGISTER_NAME' : sortField;

            const url = `${process.env.REACT_APP_SNOWFLAKE_URL}/mimo/transactions/${page}/${fetchLimit}/${startDateTime}/${endDateTime}?username=${username}&terminalId=${terminalId}&transactionId=${transactionId}&registerName=${registerName}&sortField=${orderByField}&sortOrder=${sortOrder}`;
            const response = await axios.get(url);
            setTransactions(response.data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [dateFilter, page, limit, customStartDate, customEndDate, username, terminalId, transactionId, registerName, sortField, sortOrder, groupByRegister]);

    useEffect(() => {
        fetchTransactions();
    }, [page, limit, dateFilter, customStartDate, customEndDate, username, terminalId, transactionId, registerName, groupByRegister, fetchTransactions]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleSort = (field) => {
        setSortField(field);
        setSortOrder('DESC');
    };

    const handleGroupByRegisterChange = (e) => {
        setGroupByRegister(e.target.checked);
        if (e.target.checked) {
            setDateFilter('today');
        }
    };

    useEffect(() => {
        if (groupByRegister) {
            fetchTransactions();
        }
    }, [groupByRegister, fetchTransactions]);

    return (
        <div className="container mt-5">
            <h1>Transaction List</h1>
            <Button className="mb-3" variant="success" onClick={handleExportClick}>
                Export to Excel
            </Button>

            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Export Transactions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formEntries">
                        <Form.Label>Number of Entries</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter number of entries or 'all'"
                            value={entries}
                            onChange={(e) => setEntries(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleExport}>
                        Export
                    </Button>
                </Modal.Footer>
            </Modal>
            <Form>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="dateFilter">
                            <Form.Label>Date Filter</Form.Label>
                            <Form.Select value={dateFilter} onChange={(e) => setDateFilter(e.target.value)}>
                                <option value="All">All</option>
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                                <option value="currentWeek">Current Week</option>
                                <option value="currentMonth">Current Month</option>
                                <option value="last3Days">Last 3 Days</option>
                                <option value="last7Days">Last 7 Days</option>
                                <option value="last15Days">Last 15 Days</option>
                                <option value="last30Days">Last 30 Days</option>
                                <option value="last3Months">Last 3 Months</option>
                                <option value="custom">Custom</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    {dateFilter === 'custom' && (
                        <>
                            <Col md={4}>
                                <Form.Group controlId="customStartDate">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control type="date" value={customStartDate} onChange={(e) => setCustomStartDate(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="customEndDate">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control type="date" value={customEndDate} onChange={(e) => setCustomEndDate(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </>
                    )}
                </Row>
                <Row className="mb-3">
                    <Col md={3}>
                        <Form.Group controlId="usernameFilter">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="terminalIdFilter">
                            <Form.Label>Terminal ID</Form.Label>
                            <Form.Control type="text" value={terminalId} onChange={(e) => setTerminalId(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="transactionIdFilter">
                            <Form.Label>Transaction ID</Form.Label>
                            <Form.Control type="text" value={transactionId} onChange={(e) => setTransactionId(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="registerNameFilter">
                            <Form.Label>Register Name</Form.Label>
                            <Form.Control type="text" value={registerName} onChange={(e) => setRegisterName(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={3}>
                        <Form.Group controlId="formGroupByRegister">
                            <Form.Check
                                type="checkbox"
                                label="Group by Register Name"
                                checked={groupByRegister}
                                onChange={handleGroupByRegisterChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            {loading ? (
                <Spinner animation="border" variant="primary" />
            ) : error ? (
                <Alert variant="danger">{error}</Alert>
            ) : transactions.length === 0 ? (
                <Alert variant="info">No transactions found</Alert>
            ) : (
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('TRANS_ID')}>Transaction ID</th>
                                <th onClick={() => handleSort('USER_NAME')}>User Name</th>
                                <th onClick={() => handleSort('TERMINAL_ID')}>Terminal ID</th>
                                <th onClick={() => handleSort('REGISTER_NAME')}>Register Name</th>
                                <th>Transaction Name</th>
                                <th>Transaction Total</th>
                                <th>Date and Time</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((transaction) => (
                                <tr key={transaction.TRANS_ID}>
                                    <td>{transaction.TRANS_ID}</td>
                                    <td>{transaction.USER_NAME}</td>
                                    <td>{transaction.TERMINAL_ID}</td>
                                    <td>{transaction.REGISTER_NAME}</td>
                                    <td>{transaction.TRANS_NAME}</td>
                                    <td>${transaction.TRANS_TOTAL.toFixed(2)}</td>
                                    <td>{format(new Date(transaction.TRANSACTION_DATETIME), 'MM/dd/yyyy HH:mm')}</td>
                                    <td>
                                        <Button variant="info" onClick={() => handleshowTransactionModal(transaction)}>
                                            Details
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination>
                        <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page === 1} />
                        <Pagination.Item active>{page}</Pagination.Item>
                        <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={transactions.length < limit} />
                    </Pagination>
                </>
            )}

            {selectedTransaction && (
                <Modal show={showTransactionModal} onHide={handleTransactionModalClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Transaction Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong>Transaction Total:</strong> ${selectedTransaction.TRANS_TOTAL.toFixed(2)}</p>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Denomination</th>
                                    <th>Amount</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {denominations.every(({ field }) => selectedTransaction[field] === null || selectedTransaction[field] === 0) ? (
                                    <tr>
                                        <td colSpan="3" className="text-center">
                                            No data to display, all denominations are zero.
                                        </td>
                                    </tr>
                                ) : (
                                    denominations.map(({ label, field, value }) => (
                                        selectedTransaction[field] !== null && selectedTransaction[field] !== 0 && (
                                            <tr key={field}>
                                                <td>{label}</td>
                                                <td>{selectedTransaction[field]}</td>
                                                <td>${(selectedTransaction[field] * value).toFixed(2)}</td>
                                            </tr>
                                        )
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleTransactionModalClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
}

export default TransactionList;