import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Modal, Form, Alert } from 'react-bootstrap';
import { startOfWeek, endOfWeek, format } from 'date-fns';

export const route = '/boca/schedule';
export const role = 'IT';

const predefinedSchedule = [
    { day: 'Monday', shift: 'Day Shift', location: 'ML Ticket Booth' },
    { day: 'Tuesday', shift: 'Day Shift', location: 'ML Guest Services' },
    { day: 'Wednesday', shift: 'Day Shift', location: 'SP Guest Services' },
    { day: 'Wednesday', shift: 'Night Shift', location: 'Raging Waters' },
    { day: 'Thursday', shift: 'Day Shift', location: 'SP Ticket Booth' },
    { day: 'Thursday', shift: 'Night Shift', location: 'Ocean Oasis' },
    { day: 'Friday', shift: 'Day Shift', location: 'AP' }
];

const CleaningSchedule = () => {
    const [currentWeek, setCurrentWeek] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({ location: '', shift: '', cleanedBy: '', issue: '' });
    const [feedback, setFeedback] = useState('');
    const [cleaningRecords, setCleaningRecords] = useState([]);
    const [reportedIssues, setReportedIssues] = useState([]);

    useEffect(() => {
        fetchSchedule(currentWeek);
        fetchReportedIssues();
    }, [currentWeek]);

    const fetchSchedule = async (week) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/boca/history`);
            const data = await response.json();
            const filteredData = data.filter(record => format(new Date(record.weekStart), 'yyyy-MM-dd') === format(week, 'yyyy-MM-dd'));
            setCleaningRecords(filteredData);
        } catch (error) {
            console.error('Error fetching schedule:', error);
        }
    };

    const fetchReportedIssues = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/boca/issues`);
            const data = await response.json();
            setReportedIssues(data);
        } catch (error) {
            console.error('Error fetching reported issues:', error);
        }
    };

    const handleModalSubmit = async (e) => {
        e.preventDefault();

        const url = modalData._id
            ? `${process.env.REACT_APP_API_URL}/boca/issues/${modalData._id}`
            : `${process.env.REACT_APP_API_URL}/boca/clean`;

        const method = modalData._id ? 'PUT' : 'POST';

        try {
            const response = await fetch(url, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    cleanedBy: modalData.cleanedBy,
                    weekStart: startOfWeek(new Date(), { weekStartsOn: 1 }),
                    shift: modalData.shift,
                    location: modalData.location,
                    issue: modalData.issue,
                    reportedBy: modalData.cleanedBy
                })
            });

            if (response.ok) {
                setFeedback('Cleaning record updated successfully.');
                setModalData({ location: '', shift: '', cleanedBy: '', issue: '', _id: null });
                setShowModal(false);
                fetchSchedule(currentWeek);
                fetchReportedIssues();
            } else {
                setFeedback('Failed to update cleaning record.');
            }
        } catch (error) {
            setFeedback('Error updating cleaning record.');
            console.error('Error updating cleaning record:', error);
        }
    };

    const handlePageChange = (direction) => {
        setCurrentWeek(prevWeek => new Date(prevWeek.setDate(prevWeek.getDate() + (direction * 7))));
    };

    const handleRecordClick = (location, shift) => {
        const existingRecord = cleaningRecords.find(record => record.location === location && record.shift === shift);
        const existingIssue = reportedIssues.find(issue => issue.location === location && issue.shift === shift);

        setModalData({
            location,
            shift,
            cleanedBy: existingRecord ? existingRecord.cleanedBy : '',
            issue: existingIssue ? existingIssue.issue : '',
            reportedBy: existingIssue ? existingIssue.reportedBy : '',
            _id: existingIssue ? existingIssue._id : null // Capture _id for editing
        });
        setShowModal(true);
    };

    const currentWeekStart = format(currentWeek, 'MMMM dd, yyyy');
    const currentWeekEnd = format(endOfWeek(currentWeek, { weekStartsOn: 1 }), 'MMMM dd, yyyy');

    return (
        <Container className="mt-5">
            <h1 className="text-center mb-5">Boca Cleaning Schedule</h1>
            <div className="d-flex justify-content-between mb-3">
                <Button onClick={() => handlePageChange(-1)}>Previous Week</Button>
                <h4>{currentWeekStart} - {currentWeekEnd}</h4>
                <Button onClick={() => handlePageChange(1)}>Next Week</Button>
            </div>
            {feedback && <Alert variant="info">{feedback}</Alert>}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Day</th>
                        <th>Shift</th>
                        <th>Location</th>
                        <th>Cleaned By</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {predefinedSchedule.map((entry, index) => {
                        const cleanedRecord = cleaningRecords.find(record =>
                            record.location === entry.location && record.shift === entry.shift);

                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{entry.day}</td>
                                <td>{entry.shift}</td>
                                <td>{entry.location}</td>
                                <td>{cleanedRecord ? cleanedRecord.cleanedBy : 'Not cleaned yet'}</td>
                                <td>
                                    <Button variant="success" onClick={() => handleRecordClick(entry.location, entry.shift)}>
                                        {cleanedRecord ? 'Edit' : 'Mark as Cleaned'}
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Cleaning Record</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleModalSubmit}>
                        <Form.Group controlId="location">
                            <Form.Label>Location</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter location"
                                value={modalData.location}
                                onChange={(e) => setModalData({ ...modalData, location: e.target.value })}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group controlId="shift" className="mt-3">
                            <Form.Label>Shift</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter shift"
                                value={modalData.shift}
                                onChange={(e) => setModalData({ ...modalData, shift: e.target.value })}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group controlId="cleanedBy" className="mt-3">
                            <Form.Label>Cleaned By</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your name"
                                value={modalData.cleanedBy}
                                onChange={(e) => setModalData({ ...modalData, cleanedBy: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="issue" className="mt-3">
                            <Form.Label>Report Issue (if any)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Describe any issues"
                                value={modalData.issue}
                                onChange={(e) => setModalData({ ...modalData, issue: e.target.value })}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-3">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <h3 className="text-center mt-5">Reported Issues</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Location</th>
                        <th>Issue</th>
                        <th>Reported By</th>
                        <th>Time</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {reportedIssues.map((issue, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{issue.location}</td>
                            <td>{issue.issue}</td>
                            <td>{issue.reportedBy}</td>
                            <td>{new Date(issue.reportedAt).toLocaleDateString()}</td>
                            <td>
                                <Button
                                    variant="danger"
                                    className="ml-2"
                                    onClick={async () => {
                                        try {
                                            const response = await fetch(`${process.env.REACT_APP_API_URL}/boca/issues/${issue._id}`, {
                                                method: 'DELETE',
                                                headers: { 'Content-Type': 'application/json' }
                                            });
                                            if (response.ok) {
                                                setFeedback('Issue deleted successfully.');
                                                fetchReportedIssues();
                                            } else {
                                                setFeedback('Failed to delete issue.');
                                            }
                                        } catch (error) {
                                            setFeedback('Error deleting issue.');
                                            console.error('Error deleting issue:', error);
                                        }
                                    }}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default CleaningSchedule;