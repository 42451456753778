import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Accordion, Card, Button, Table, Pagination } from 'react-bootstrap';

export const route = "/it/airfiber";
export const role = "IT";

const AirFiberTable = () => {
    const [records, setRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [error, setError] = useState(null);
    const [isPosting, setIsPosting] = useState(false);
    const pageSize = 5;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/fiber`, {
                    params: { page: currentPage, size: pageSize }
                });

                if (response.data.records && Array.isArray(response.data.records)) {
                    setRecords(response.data.records);
                    setTotalPages(Math.ceil(response.data.total / pageSize));
                } else {
                    setError('Unexpected data structure');
                }
            } catch (err) {
                setError('Error fetching device data');
                console.error(err);
            }
        };

        fetchData();
    }, [currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleRunTest = async () => {
        setIsPosting(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/fiber`);

            records.unshift(response.data); // Add new record at the beginning
            setTotalPages(Math.ceil(records.length / pageSize)); // Recalculate total pages
            setCurrentPage(1); // Reset to the first page to see the new results
        } catch (err) {
            console.error('Error running the test:', err);
            setError('Error running the test');
        } finally {
            setIsPosting(false);
        }
    };

    const extractMbpsValue = (receiveString) => {
        const numericValue = parseFloat(receiveString);
        return isNaN(numericValue) ? 0 : numericValue;
    };

    const getReceiveStyle = (receiveString) => {
        const receiveValue = extractMbpsValue(receiveString);

        if (receiveValue < 350) {
            return { color: 'red', fontWeight: 'bold' };
        } else if (receiveValue < 549) {
            return { color: 'orange', fontWeight: 'bold' };
        } else if (receiveValue < 699) {
            return { color: 'khaki', fontWeight: 'bold' };
        } else {
            return { fontWeight: 'bold' };
        }
    };

    const groupByPairs = (devices) => {
        const pairedResults = [];
        const deviceMap = {};
    
        // First, map the devices by their name
        devices.forEach(device => {
            deviceMap[device.deviceName] = device;
        });
    
        // Now, pair them based on the pairing rules you want
        const pairs = [
            ['ML West (10.1.1.91)', 'SP (10.1.1.45)'],
            ['DB (10.1.1.95)', 'ML East (10.1.1.88)'],
            ['AP (10.1.1.28)', 'ML (10.1.1.24)']
        ];
    
        pairs.forEach(([deviceName1, deviceName2]) => {
            const device1 = deviceMap[deviceName1];
            const device2 = deviceMap[deviceName2];
    
            if (device1 && device2) {
                pairedResults.push([device1, device2]);
            }
        });
    
        console.log("Paired Results: ", pairedResults);
        return pairedResults;
    };    

    const renderPairedDevices = (devices) => {
        console.log("Devices to Render: ", devices);
        const pairs = groupByPairs(devices);
    
        if (!pairs.length) {
            return <tr><td colSpan="5">No devices found</td></tr>;
        }
    
        return pairs.map(([device1, device2], pairIndex) => (
            <React.Fragment key={pairIndex}>
                <tr><td colSpan="5" style={{borderBottom: '3px solid grey'}}></td></tr>
                <tr>
                    <td>{device1.deviceName}</td>
                    <td style={getReceiveStyle(device1.receive)}>{device1.receive}</td>
                    <td>{device1.txPower}</td>
                    <td>{device1.signalStrength}</td>
                    <td>{device1.remoteSignalStrength}</td>
                </tr>
                <tr>
                    <td>{device2.deviceName}</td>
                    <td style={getReceiveStyle(device2.receive)}>{device2.receive}</td>
                    <td>{device2.txPower}</td>
                    <td>{device2.signalStrength}</td>
                    <td>{device2.remoteSignalStrength}</td>
                </tr>
                <tr><td colSpan="5" style={{borderBottom: '3px solid grey'}}></td></tr>
            </React.Fragment>
        ));
    }; 

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="container mt-4">
            <h2>AirFiber Device Data</h2>
            <Button
                variant="primary"
                onClick={handleRunTest}
                disabled={isPosting}
                className="mb-3"
            >
                {isPosting ? 'Running Test...' : 'Run New Test'}
            </Button>
            <Accordion defaultActiveKey="0">
                {records.map((record, index) => (
                    <Card key={record._id}>
                        <Accordion.Item eventKey={String(index)}>
                            <Accordion.Header>
                                {new Date(record.timestamp).toLocaleString()}
                            </Accordion.Header>
                            <Accordion.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Device Name (IP)</th>
                                            <th>Receive</th>
                                            <th>TX Power</th>
                                            <th>Signal Strength</th>
                                            <th>Remote Signal Strength</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderPairedDevices(record.devices)}
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Card>
                ))}
            </Accordion>
            <Pagination className="justify-content-center mt-4">
                {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </Pagination.Item>
                ))}
            </Pagination>
        </div>
    );
};

export default AirFiberTable;