import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import authConfig from '../auth_config.json';

const ProtectedRoute = ({ role }) => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const hasRequiredRole = () => {
    const roles = user && user[`${authConfig.namespace}roles`];
    if (!roles) {
      return false;
    }
    return roles.includes(role);
  };

  if (!isAuthenticated) {
    loginWithRedirect({ appState: { targetUrl: location.pathname } });
    return <div>Loading...</div>;
  }

  return hasRequiredRole() ? (
    <Outlet />
  ) : (
    <h3 className="m-5">
      You do not have access to this. To request access, email Aaron Long [aaron.long@moreyspiers.com]
    </h3>
  );
};

export default ProtectedRoute;