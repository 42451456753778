import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'; // Ensure Leaflet's CSS is imported

import 'leaflet/dist/images/layers-2x.png'
import 'leaflet/dist/images/layers.png'
import 'leaflet/dist/images/marker-icon-2x.png'
import 'leaflet/dist/images/marker-icon.png'
import 'leaflet/dist/images/marker-shadow.png'

const customIcon = new L.Icon({
    iconUrl: '/marker-icon.png',
    iconRetinaUrl: '/marker-icon-2x.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: '/marker-shadow.png',
    shadowSize: [41, 41]
});

function LocationPicker({ initialPosition, onLocationSelected }) {
    const [markerPosition, setMarkerPosition] = useState(initialPosition || null);

    useEffect(() => {
        // Update marker position when initialPosition changes
        if (initialPosition) {
            setMarkerPosition(initialPosition);
        }
    }, [initialPosition]);

    function LocationMarker() {
        const map = useMapEvents({
            click(e) {
                setMarkerPosition(e.latlng);
                onLocationSelected(e.latlng);
                map.flyTo(e.latlng, map.getZoom());
            }
        });

        return markerPosition === null ? null : (
            <Marker position={markerPosition}></Marker>
        );
    }

    return (
        <MapContainer center={markerPosition || [38.9876, -74.8149]} zoom={18} style={{ height: '400px', width: '100%' }} icon={customIcon}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <LocationMarker />
        </MapContainer>
    );
}

export default LocationPicker;
