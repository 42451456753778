import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Card,
  Button,
  Form,
  Alert,
  ListGroup,
  Spinner,
  Badge,
  Pagination,
  Modal,
} from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';

export const route = "/employee/reports";
export const role = "IT";

function EmployeeReports() {
  const [reports, setReports] = useState([]);
  const [currentReport, setCurrentReport] = useState('');
  const [selectedReport, setSelectedReport] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingReports, setLoadingReports] = useState(true);
  const { user } = useAuth0();
  const userId = user.sub.replace(/^auth0\|/, '');
  const username = user.name || user.email;

  // Global tasks state
  const [globalTasks, setGlobalTasks] = useState([]);
  const [newTaskDescription, setNewTaskDescription] = useState('');
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [editingTask, setEditingTask] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const reportsPerPage = 5;

  // Today's tasks state
  const [todayTasks, setTodayTasks] = useState([]);

  // Fetch reports
  const fetchReports = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reports?userId=${userId}`);
      const data = await response.json();
      setReports(data);
      setLoadingReports(false);
    } catch (error) {
      console.error('Error fetching reports:', error);
      setErrorMessage('Error fetching reports');
      setLoadingReports(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  // Fetch global tasks
  const fetchGlobalTasks = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/tasks`);
      const data = await response.json();
      setGlobalTasks(data);
      setLoadingTasks(false);
    } catch (error) {
      console.error('Error fetching global tasks:', error);
      setErrorMessage('Error fetching tasks');
      setLoadingTasks(false);
    }
  }, []);

  useEffect(() => {
    fetchGlobalTasks();
  }, [fetchGlobalTasks]);

  // Get incomplete tasks from the previous day
  useEffect(() => {
    if (reports.length > 0) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      const previousReport = reports.find((report) => {
        const reportDate = new Date(report.reportDate);
        reportDate.setHours(0, 0, 0, 0);
        return reportDate.getTime() === yesterday.getTime();
      });

      if (previousReport && previousReport.tasks) {
        const incompleteTasks = previousReport.tasks.filter(task => !task.isCompleted);
        setTodayTasks(incompleteTasks.map(task => ({ ...task, reportId: previousReport._id })));
      }
    }
  }, [reports]);

  const handleNewTaskSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/tasks`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          description: newTaskDescription,
          createdBy: userId,
          createdByName: username,
        }),
      });

      if (response.ok) {
        await fetchGlobalTasks();
        setNewTaskDescription('');
      } else {
        const errorData = await response.text();
        setErrorMessage(errorData);
      }
    } catch (error) {
      console.error('Error creating task:', error);
      setErrorMessage('Error creating task');
    }
  };

  // Handle task deletion
  const handleDeleteTask = async () => {
    if (!taskToDelete) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/tasks/${taskToDelete._id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        await fetchGlobalTasks();
        setShowDeleteModal(false);
        setTaskToDelete(null);
      } else {
        const errorData = await response.text();
        setErrorMessage(errorData);
      }
    } catch (error) {
      console.error('Error deleting task:', error);
      setErrorMessage('Error deleting task');
    }
  };

  // Handle task editing
  const handleEditTaskSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/tasks/${editingTask._id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ description: editingTask.description }),
      });

      if (response.ok) {
        await fetchGlobalTasks();
        setEditingTask(null);
      } else {
        const errorData = await response.text();
        setErrorMessage(errorData);
      }
    } catch (error) {
      console.error('Error updating task:', error);
      setErrorMessage('Error updating task');
    }
  };

  const handleReportChange = (e) => {
    setCurrentReport(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const method = selectedReport ? 'PATCH' : 'POST';
      const url = selectedReport
        ? `${process.env.REACT_APP_API_URL}/reports/${selectedReport._id}`
        : `${process.env.REACT_APP_API_URL}/reports`;

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          username,
          content: currentReport,
        }),
      });

      if (response.ok) {
        await fetchReports();
        setCurrentReport('');
        setSelectedReport(null);
      } else {
        const errorData = await response.text();
        setErrorMessage(errorData);
      }
    } catch (error) {
      console.error('Error submitting report:', error);
      setErrorMessage('Error submitting report');
    }
  };

  const handleEdit = (report) => {
    setSelectedReport(report);
    setCurrentReport(report.content);
  };

  const handleCancelEdit = () => {
    setSelectedReport(null);
    setCurrentReport('');
  };

  // Pagination calculations
  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = reports.slice(indexOfFirstReport, indexOfLastReport);
  const totalPages = Math.ceil(reports.length / reportsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loadingReports || loadingTasks) {
    return <Spinner animation="border" variant="primary" className="d-block mx-auto my-5" />;
  }

  const handleTaskCompletion = async (reportId, taskId, isCompleted) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reports/${reportId}/tasks/${taskId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId, isCompleted }),
        }
      );

      if (response.ok) {
        await fetchReports();
      } else {
        const errorData = await response.text();
        setErrorMessage(errorData);
      }
    } catch (error) {
      console.error('Error updating task:', error);
      setErrorMessage('Error updating task');
    }
  };

  return (
    <Container className="my-5">
      <Card className="mb-4 shadow-sm">
        <Card.Header>
          <h4>{selectedReport ? 'Edit Report' : 'New Report'}</h4>
        </Card.Header>
        <Card.Body>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="reportContent">
              <Form.Label>What did you do today?</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={currentReport}
                onChange={handleReportChange}
                placeholder="Write your report here..."
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              {selectedReport ? 'Update Report' : 'Submit Report'}
            </Button>
            {selectedReport && (
              <Button variant="secondary" onClick={handleCancelEdit} className="mt-3 ms-2">
                Cancel
              </Button>
            )}
          </Form>
        </Card.Body>
      </Card>

      {/* Team Tasks Section */}
      <Card className="mb-4 shadow-sm">
        <Card.Header>
          <h4>Team Tasks</h4>
        </Card.Header>
        <Card.Body>
          {/* New Task Form */}
          <Form onSubmit={handleNewTaskSubmit} className="mb-3">
            <Form.Group controlId="newTaskDescription">
              <Form.Label>Add a new task</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter task description"
                value={newTaskDescription}
                onChange={(e) => setNewTaskDescription(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-2">
              Add Task
            </Button>
          </Form>

          {/* Task List */}
          {globalTasks.length === 0 ? (
            <p>No team tasks available.</p>
          ) : (
            <ListGroup>
              {globalTasks.map((task) => (
                <ListGroup.Item key={task._id}>
                  {editingTask && editingTask._id === task._id ? (
                    <Form onSubmit={handleEditTaskSubmit}>
                      <Form.Control
                        type="text"
                        value={editingTask.description}
                        onChange={(e) =>
                          setEditingTask({ ...editingTask, description: e.target.value })
                        }
                        required
                      />
                      <Button variant="primary" type="submit" className="mt-2">
                        Save
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => setEditingTask(null)}
                        className="mt-2 ms-2"
                      >
                        Cancel
                      </Button>
                    </Form>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <strong>{task.description}</strong>
                        <br />
                        <small>
                          Created by: {task.createdByName} on{' '}
                          {new Date(task.createdAt).toLocaleDateString()}
                        </small>
                      </div>
                      <div>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => setEditingTask(task)}
                          className="me-2"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => {
                            setTaskToDelete(task);
                            setShowDeleteModal(true);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Card.Body>
      </Card>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this task: "{taskToDelete?.description}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteTask}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Today's Tasks Section */}
      {todayTasks.length > 0 && (
        <Card className="mb-4 shadow-sm">
          <Card.Header>
            <h4>Today's Tasks</h4>
          </Card.Header>
          <Card.Body>
            <ListGroup>
              {todayTasks.map((task) => (
                <ListGroup.Item key={task._id}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>{task.description}</span>
                    <Form.Check
                      type="checkbox"
                      label="Completed"
                      checked={task.isCompleted}
                      onChange={(e) =>
                        handleTaskCompletion(task.reportId, task._id, e.target.checked)
                      }
                    />
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      )}

      <Card className="mb-4 shadow-sm">
        <Card.Header>
          <h4>Your Past Reports</h4>
        </Card.Header>
        <Card.Body>
          {reports.length === 0 ? (
            <p>You have no past reports.</p>
          ) : (
            <>
              <ListGroup variant="flush">
                {currentReports.map((report) => {
                  const reportDate = new Date(report.reportDate).toLocaleDateString();
                  const canEdit = !report.isFinalized;
                  return (
                    <ListGroup.Item key={report._id}>
                      <Card className="shadow-sm">
                        <Card.Header>
                          <strong>{reportDate}</strong>
                        </Card.Header>
                        <Card.Body>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <p>{report.content}</p>
                            </div>
                            <div>
                              {canEdit ? (
                                <Button variant="primary" onClick={() => handleEdit(report)}>
                                  Edit
                                </Button>
                              ) : (
                                <Badge bg="secondary">Finalized</Badge>
                              )}
                            </div>
                          </div>
                          {report.generatedSummary && (
                            <Alert variant="info" className="mt-2">
                              <strong>Summary:</strong> {report.generatedSummary}
                              {report.tasks.length > 0 && (
                                <>
                                  <hr />
                                  <strong>Tasks:</strong>
                                  <ListGroup>
                                    {report.tasks.map((task) => (
                                      <ListGroup.Item key={task._id}>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <span>{task.description}</span>
                                          <Form.Check
                                            type="checkbox"
                                            label="Completed"
                                            checked={task.isCompleted}
                                            disabled
                                          />
                                        </div>
                                      </ListGroup.Item>
                                    ))}
                                  </ListGroup>
                                </>
                              )}
                            </Alert>
                          )}
                        </Card.Body>
                      </Card>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>

              {/* Pagination Controls */}
              <Pagination className="mt-3">
                <Pagination.Prev
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: totalPages }, (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}

export default EmployeeReports;