import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Button, Modal, Form, Card, Dropdown } from 'react-bootstrap';
import { FaFolder } from 'react-icons/fa';

export const route = "/media";
export const role = "IT";

const MediaPage = () => {
    const [folders, setFolders] = useState([]);
    const [files, setFiles] = useState([]);
    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [showUploadFileModal, setShowUploadFileModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [newFolderName, setNewFolderName] = useState('');
    const [uploadFile, setUploadFile] = useState(null);
    const [accessToken, setAccessToken] = useState('');

    // Function to authenticate with the AbleSign API
    const authenticate = useCallback(async () => {
        try {
            const response = await axios.post('https://api.ablesign.tv/api/auth/signin', {
                username: 'aaron.long@moreyspiers.com',
                password: 'MVLAELhvl$729'
            });
            setAccessToken(response.data.accessToken);
        } catch (error) {
            console.error('Error during authentication:', error.message);
        }
    }, []);

    // Function to fetch folders and files
    const fetchMedia = useCallback(async (folderId = null) => {
        try {
            const folderResponse = await axios.get('https://api.ablesign.tv/api/folders', {
                headers: {
                    'X-Access-Token': accessToken
                },
                params: {
                    limit: 999,
                    sortBy: 'creation_date_desc'
                }
            });

            const fileResponse = await axios.get('https://api.ablesign.tv/api/files', {
                headers: {
                    'X-Access-Token': accessToken
                },
                params: {
                    limit: 999,
                    sortBy: 'creation_date_desc',
                    folderId: folderId,
                    formats: 'Image,Video,none',
                    orientations: 'landscape,none,portrait'
                }
            });

            setFolders(folderResponse.data.folders.filter(folder => folder.parentFolderId === folderId));
            setFiles(fileResponse.data.mediafiles);
            setCurrentFolderId(folderId);
        } catch (error) {
            console.error('Error fetching media:', error.message);
        }
    }, [accessToken]);

    // Function to handle folder creation
    const handleCreateFolder = async () => {
        try {
            await axios.post('https://api.ablesign.tv/api/folders', {
                title: newFolderName,
                parentFolderId: currentFolderId
            }, {
                headers: {
                    'X-Access-Token': accessToken
                }
            });
            setShowCreateFolderModal(false);
            setNewFolderName('');
            fetchMedia(currentFolderId); // Refresh media list
        } catch (error) {
            console.error('Error creating folder:', error.message);
        }
    };

    // Function to handle file upload
    const handleUploadFile = async () => {
        if (uploadFile) {
            try {
                const initResponse = await axios.post('https://api.ablesign.tv/api/files/init_upload', {
                    filename: uploadFile.name,
                    mimeType: uploadFile.type,
                    size: uploadFile.size,
                    folderId: currentFolderId
                }, {
                    headers: {
                        'X-Access-Token': accessToken
                    }
                });

                const uploadUrl = initResponse.data.url;
                await axios.put(uploadUrl, uploadFile, {
                    headers: {
                        'Content-Type': uploadFile.type
                    }
                });

                await axios.post('https://api.ablesign.tv/api/files/finish_upload', {
                    uploadId: initResponse.data.uploadId
                }, {
                    headers: {
                        'X-Access-Token': accessToken
                    }
                });

                setShowUploadFileModal(false);
                setUploadFile(null);
                fetchMedia(currentFolderId); // Refresh media list
            } catch (error) {
                console.error('Error uploading file:', error.message);
            }
        }
    };

    // Function to handle item deletion (file or folder)
    const handleDeleteItem = async () => {
        if (itemToDelete) {
            try {
                if (itemToDelete.type === 'file') {
                    await axios.delete(`https://api.ablesign.tv/api/files/${itemToDelete.id}`, {
                        headers: {
                            'X-Access-Token': accessToken
                        }
                    });
                    setFiles(files.filter(file => file.id !== itemToDelete.id));
                } else if (itemToDelete.type === 'folder') {
                    await axios.delete(`https://api.ablesign.tv/api/folders/${itemToDelete.id}`, {
                        headers: {
                            'X-Access-Token': accessToken
                        }
                    });
                    setFolders(folders.filter(folder => folder.id !== itemToDelete.id));
                }
                setShowDeleteModal(false);
            } catch (error) {
                console.error('Error deleting item:', error.message);
            }
        }
    };

    // Show confirmation modal before deleting
    const confirmDeleteItem = (item, type) => {
        setItemToDelete({ ...item, type });
        setShowDeleteModal(true);
    };

    // Navigate into a folder
    const navigateIntoFolder = (folderId) => {
        fetchMedia(folderId);
    };

    // Navigate back to the parent folder
    const navigateUp = () => {
        if (currentFolderId) {
            const parentFolder = folders.find(folder => folder.id === currentFolderId)?.parentFolderId || null;
            fetchMedia(parentFolder);
        } else {
            fetchMedia(null);
        }
    };

    useEffect(() => {
        authenticate().then(() => {
            if (accessToken) {
                fetchMedia();
            }
        });
    }, [authenticate, accessToken, fetchMedia]);

    return (
        <div className="container">
            <h1>Media</h1>
            <Button variant="primary" onClick={() => setShowCreateFolderModal(true)}>Create Folder</Button>
            <Button variant="secondary" className="ms-2" onClick={() => setShowUploadFileModal(true)}>Upload File</Button>
            {currentFolderId && (
                <Button variant="link" className="ms-2" onClick={navigateUp}>Go Back</Button>
            )}
            <div className="media-list mt-4 row">
                {folders.map(folder => (
                    <div key={folder.id} className="col-md-3 mb-4">
                        <Card
                            className="card shadow-sm"
                            style={{ height: '180px', cursor: 'pointer' }}
                            onClick={() => navigateIntoFolder(folder.id)}
                        >
                            <Card.Body className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <FaFolder size={40} className="me-3" />
                                    <Card.Title className="mb-0">{folder.title}</Card.Title>
                                </div>
                                <Dropdown
                                    align="end"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm">
                                        &#x22EE;
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => confirmDeleteItem(folder, 'folder')}>
                                            Delete Folder
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
                {files.map(file => (
                    <div key={file.id} className="col-md-3 mb-4">
                        <Card className="card shadow-sm" style={{ height: '180px' }}>
                            <Card.Img variant="top" src={file.thumbnail} alt={file.title} style={{ height: '120px', objectFit: 'contain' }} />
                            <Card.Body className="d-flex justify-content-between align-items-center">
                                <Card.Title className="mb-0">{file.title}</Card.Title>
                                <Dropdown align="end">
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm">
                                        &#x22EE;
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => confirmDeleteItem(file, 'file')}>
                                            Delete File
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>

            {/* Create Folder Modal */}
            <Modal show={showCreateFolderModal} onHide={() => setShowCreateFolderModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Folder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="folderName">
                            <Form.Label>Folder Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter folder name"
                                value={newFolderName}
                                onChange={(e) => setNewFolderName(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCreateFolderModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCreateFolder}>
                        Create Folder
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Upload File Modal */}
            <Modal show={showUploadFileModal} onHide={() => setShowUploadFileModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="fileUpload">
                            <Form.Label>Choose File</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e) => setUploadFile(e.target.files[0])}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUploadFileModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleUploadFile}>
                        Upload File
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the {itemToDelete?.type} titled "{itemToDelete?.title}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteItem}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MediaPage;