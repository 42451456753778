import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import authConfig from '../auth_config.json';

const Auth0ProviderWithHistory = ({ children }) => {
  const onRedirectCallback = () => {
    if (window.innerWidth > 768) {
      window.location.assign(window.location.origin);
    }
  };

  return (
    <Auth0Provider
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      audience={authConfig.audience}
      redirectUri={authConfig.redirectUri}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;