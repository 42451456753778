import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../../CSS/KitchenPrinterList.module.css'; // Import CSS Module

const KitchenPrinterList = ({ devices, handleDelete }) => {
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <h2>Kitchen Printer Devices</h2>
            <table className={`table table-responsive ${styles.table}`}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Friendly Name</th>
                        <th>Name</th>
                        <th>Pier</th>
                        <th>IP</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {devices.map((device, index) => (
                        <tr key={device._id}>
                            <td>{index + 1}</td>
                            <td>
                                <Link to={`/it/device/${device._id}`}>{device.friendlyName}</Link>
                            </td>
                            <td>{device.name}</td>
                            <td>{device.pier}</td>
                            <td>{device.ip}</td>
                            <td>
                                <span className={`badge ${device.isOnline ? 'bg-success' : 'bg-danger'} ${styles.badge}`}>
                                    {device.isOnline ? 'Online' : 'Offline'}
                                </span>
                            </td>
                            <td>
                                <button onClick={() => navigate(`/it/device/edit/${device._id}`)} className={`btn btn-primary ${styles.btn}`}>Edit</button>
                                <button onClick={() => handleDelete(device._id)} className={`btn btn-danger ${styles.btn}`}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default KitchenPrinterList;