import React, { useState, useEffect } from 'react';
import { format, addDays } from 'date-fns';
import { Container, Row, Col, Form, Table, Spinner, Alert, Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';

export const route = "/mimo/user-summary";
export const role = "MIMO";

const UserSummary = () => {
    const today = format(new Date(), 'yyyy-MM-dd');
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [userId, setUserId] = useState('');
    const [userSummaries, setUserSummaries] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchUsers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SNOWFLAKE_URL}/mimo/users`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setUsers(data);
        } catch {
            setError('Failed to fetch users');
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        const fetchUserSummary = async () => {
            setLoading(true);
            setError(null);
            try {
                const adjustedStartDate = format(addDays(new Date(startDate), 1), 'yyyy-MM-dd');
                const adjustedEndDate = format(addDays(new Date(endDate), 1), 'yyyy-MM-dd');

                const response = await fetch(`${process.env.REACT_APP_SNOWFLAKE_URL}/mimo/user-summary/${adjustedStartDate}/${adjustedEndDate}/?userId=${userId}`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true'
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setUserSummaries(data);
            } catch {
                setError('Failed to fetch user summary data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserSummary();
    }, [startDate, endDate, userId]);

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    const handleExportToExcel = () => {
        const worksheetData = userSummaries.map(summary => ({
            'User ID': summary.USER_ID,
            'User Name': summary.USER_NAME,
            'Business Date': summary.BUSINESS_DATE,
            'Register ID': summary.REGISTER_ID,
            'Register Name': summary.REGISTER_NAME,
            'Dispense': formatCurrency(summary.DISPENSE_AMOUNT),
            'Deposit': formatCurrency(summary.DEPOSIT_AMOUNT),
            'Total': formatCurrency(summary.TOTAL_AMOUNT),
        }));

        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'User Summary');

        XLSX.writeFile(workbook, `User_Summary_${startDate}_to_${endDate}.xlsx`);
    };

    return (
        <Container>
            <h1 className="mt-4">User Summary</h1>
            <Form>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="startDate">
                            <Form.Label>Start Date:</Form.Label>
                            <Form.Control
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="endDate">
                            <Form.Label>End Date:</Form.Label>
                            <Form.Control
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="userId">
                            <Form.Label>User ID:</Form.Label>
                            <Form.Control
                                as="select"
                                value={userId}
                                onChange={(e) => setUserId(e.target.value)}
                            >
                                <option value="">All Users</option>
                                {users.map(user => (
                                    <option key={user.USER_ID} value={user.USER_ID}>{user.USER_NAME}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            {loading ? (
                <Spinner animation="border" variant="primary" />
            ) : error ? (
                <Alert variant="danger">{error}</Alert>
            ) : userSummaries.length === 0 ? (
                <Alert variant="info">No data found for the selected dates.</Alert>
            ) : (
                <>
                    <Button onClick={handleExportToExcel} variant="success" className="mb-3">
                        Export to Excel
                    </Button>
                    <Table striped bordered hover className="mt-4 mb-3">
                        <thead>
                            <tr>
                                <th>User ID</th>
                                <th>User Name</th>
                                <th>Business Date</th>
                                <th>Register ID</th>
                                <th>Register Name</th>
                                <th>Dispense</th>
                                <th>Deposit</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userSummaries.map((summary, index) => (
                                <tr key={index}>
                                    <td>{summary.USER_ID}</td>
                                    <td>{summary.USER_NAME}</td>
                                    <td>{summary.BUSINESS_DATE}</td>
                                    <td>{summary.REGISTER_ID}</td>
                                    <td>{summary.REGISTER_NAME}</td>
                                    <td>{formatCurrency(summary.DISPENSE_AMOUNT)}</td>
                                    <td>{formatCurrency(summary.DEPOSIT_AMOUNT)}</td>
                                    <td>{formatCurrency(summary.TOTAL_AMOUNT)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            )}
        </Container>
    );
};

export default UserSummary;