import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Table, Spinner, Alert, Row, Col, Card } from 'react-bootstrap';

export const route = "/mimo/dashboard";
export const role = "MIMO";

function MimoDashboard() {
    const [noteTypeData, setNoteTypeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchNoteTypeData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SNOWFLAKE_URL}/mimo/dashboard`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setNoteTypeData(data);
            } catch (error) {
                setError('Failed to fetch note type data');
            } finally {
                setLoading(false);
            }
        };

        fetchNoteTypeData();
    }, []);

    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    const formatCurrency = (value) => `$${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

    const calculateCapacity = (quantity, max) => {
        return `${((quantity / max) * 100).toFixed(2)}%`;
    };

    const getCapacity = (noteValue, quantity) => {
        const capacities = {
            '0.01': 9000,
            '0.05': 6000,
            '0.1': 12000,
            '0.25': 5000,
            '1': 3400,
            '0.5': 1200,
            'default': 3400
        };

        const maxCapacity = capacities[noteValue] || capacities['default'];
        return calculateCapacity(quantity, maxCapacity);
    };

    const calculateTotalAmount = (data) => {
        return data.reduce((total, item) => {
            return total + item.AMOUNT;
        }, 0);
    };

    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const timeZoneOffset = date.getTimezoneOffset() * 60000;
    //     const localDate = new Date(date.getTime() - timeZoneOffset);
    //     const formattedDate = localDate.toLocaleString('en-us', { timeZone: 'America/New_York' })
        
    //     return formattedDate
    // };

    return (
        <div className="container mt-5 mb-5">
            <h1>MIMO Dashboard</h1>
            <Tabs defaultActiveKey={noteTypeData[0]?.terminalId || ''} id="mimo-dashboard-tabs">
                {noteTypeData.map((terminal, index) => (
                    <Tab eventKey={terminal.terminalId} title={terminal.terminalName} key={index}>
                        <Row className="mb-4 mt-4">
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>{terminal.terminalName}</Card.Title>
                                        <Card.Text>
                                            <h4>Total Amount: {formatCurrency(calculateTotalAmount(terminal.data.cash) + calculateTotalAmount(terminal.data.coin))}</h4>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <h3>Cash</h3>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Cassette</th>
                                            <th>Note Value</th>
                                            <th>Count</th>
                                            <th>Capacity</th>
                                            <th>Total</th>
                                            <th>Last Used</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {terminal.data.cash.map((cashItem, idx) => (
                                            <tr key={idx}>
                                                <td>{cashItem.CASSETTE_NAME}</td>
                                                <td>{formatCurrency(cashItem.NOTE_VALUE)}</td>
                                                <td>{cashItem.QUANTITIES}</td>
                                                <td>{getCapacity(cashItem.NOTE_VALUE, cashItem.QUANTITIES)}</td>
                                                <td>{formatCurrency(cashItem.AMOUNT)}</td>
                                                <td>{new Date(cashItem._FIVETRAN_SYNCED).toLocaleString()}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={6}>
                                <h3>Coin</h3>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Hopper</th>
                                            <th>Note Value</th>
                                            <th>Count</th>
                                            <th>Capacity</th>
                                            <th>Total</th>
                                            <th>Last Synced</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {terminal.data.coin.sort((a, b) => a.CASSETTE_NAME.localeCompare(b.CASSETTE_NAME)).map((coinItem, idx) => (
                                            <tr key={idx}>
                                                <td>{coinItem.CASSETTE_NAME}</td>
                                                <td>{formatCurrency(coinItem.NOTE_VALUE)}</td>
                                                <td>{coinItem.QUANTITIES}</td>
                                                <td>{getCapacity(coinItem.NOTE_VALUE, coinItem.QUANTITIES)}</td>
                                                <td>{formatCurrency(coinItem.AMOUNT)}</td>
                                                <td>{new Date(coinItem._FIVETRAN_SYNCED).toLocaleString()}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Tab>
                ))}
            </Tabs>
        </div>
    );
}

export default MimoDashboard;