import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const route = "/it/micros/list";
export const role = "IT";

function MicrosList() {
    const [micros, setMicros] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchMicros();
    }, []);

    const fetchMicros = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/micros`);
        const data = await response.json();
        setMicros(data);
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this device?");
        if (confirmDelete) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/micros/${id}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                fetchMicros(); // Refresh the list after deletion
            } else {
                alert('Failed to delete the device');
            }
        }
    };

    return (
        <div className="container mb-5 mt-5">
            <h1>Micros Device List</h1>
            <button onClick={() => navigate('/it/micros/create')} className="btn btn-primary mt-3 mb-3">Create +</button>
            <table className="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Pier</th>
                        <th>Done</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {micros.map((device, index) => (
                        <tr key={device._id}>
                            <td>{index + 1}</td>
                            <td>{device.name}</td>
                            <td>{device.type}</td>
                            <td>{device.pier}</td>
                            <td>
                                <span className={`badge ${device.done ? 'bg-success' : 'bg-danger'}`}>
                                    {device.done ? 'True' : 'False'}
                                </span>
                            </td>
                            <td>
                                <button onClick={() => navigate(`/it/micros/edit/${device._id}`)} className="btn btn-primary me-2">Edit</button>
                                <button onClick={() => handleDelete(device._id)} className="btn btn-danger">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default MicrosList;