import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LocationPicker from '../../components/LocationPicker';

export const route = "/it/micros/create";
export const role = "IT";

function CreateMicros() {
    const [microsData, setMicrosData] = useState({
        name: '',
        type: '',
        pier: '',
        done: false,
        location: { lat: 38.9876, lng: -74.8149 }
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setMicrosData({
            ...microsData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleLocationChange = (latlng) => {
        setMicrosData({ ...microsData, location: { lat: latlng.lat, lng: latlng.lng } });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/micros`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(microsData)
            });

            if (response.ok) {
                navigate('/it/micros/list');
            } else {
                alert('Failed to add device');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-4">Add New Micros Device</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input type="text" className="form-control" name="name" value={microsData.name} onChange={handleChange} placeholder="Name" />
                </div>
                <div className="mb-3">
                    <label htmlFor="type" className="form-label">Type</label>
                    <select name="type" value={microsData.type} onChange={handleChange} className="form-select">
                        <option value="">Select a Type</option>
                        <option value="EPSON">EPSON</option>
                        <option value="Micros">Micros</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="pier" className="form-label">Pier</label>
                    <select name="pier" value={microsData.pier} onChange={handleChange} className="form-select">
                        <option value="">Select a pier</option>
                        <option value="Mariner's Pier">Mariner's Pier</option>
                        <option value="Surfside Pier">Surfside Pier</option>
                        <option value="Adventure Pier">Adventure Pier</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="done" className="form-label">Done</label>
                    <input type="checkbox" className="ms-3 form-check-input" name="done" checked={microsData.done} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="location" className="form-label">Location</label>
                    <LocationPicker onLocationSelected={handleLocationChange} />
                </div>
                <button type="submit" className="btn mb-5 btn-primary">Add Device</button>
            </form>
        </div>
    );
}

export default CreateMicros;