import React, { useEffect, useState } from 'react';
import { Container, Table, Spinner, Alert, Pagination, Form, Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';

export const route = "/officer/list";
export const role = "Officer";

const OfficerList = () => {
    const [officers, setOfficers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [dateFilter, setDateFilter] = useState('');

    const fetchOfficers = async (page = 1, date = '') => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/officers?page=${page}&date=${date}`);
            const data = await response.json();

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            setOfficers(data.officers);
            setTotalPages(data.totalPages);
            setPage(data.currentPage);
        } catch (error) {
            setError('Failed to fetch officers');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOfficers();
    }, []);

    const handlePageChange = (newPage) => {
        fetchOfficers(newPage, dateFilter);
    };

    const handleDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        selectedDate.setDate(selectedDate.getDate() + 1); // Add one day
        const adjustedDate = selectedDate.toISOString().split('T')[0]; // Convert to 'YYYY-MM-DD' format
        setDateFilter(adjustedDate);
        fetchOfficers(1, adjustedDate);
    };

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(officers);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Officers');
        const fileName = `Officers_Check_In.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    return (
        <Container>
            <h1 className="mt-4">Officer List</h1>
            <Form.Group controlId="dateFilter" className="mb-3">
                <Form.Label>Filter by Date</Form.Label>
                <Form.Control type="date" value={dateFilter} onChange={handleDateChange} />
            </Form.Group>
            <Button className="mb-3" onClick={exportToExcel}>Export to Excel</Button>
            {loading ? (
                <Spinner animation="border" variant="primary" />
            ) : error ? (
                <Alert variant="danger">{error}</Alert>
            ) : officers.length === 0 ? (
                <Alert variant="info">No officers found</Alert>
            ) : (
                <>
                    <Table striped bordered hover className="mt-4 mb-3">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Badge Number</th>
                                <th>Department</th>
                                <th>Pier Assignment</th>
                                <th>Check-In Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {officers.map((officer) => (
                                <tr key={officer._id}>
                                    <td>{officer.name}</td>
                                    <td>{officer.badgeNumber}</td>
                                    <td>{officer.department}</td>
                                    <td>{officer.pierAssignment}</td>
                                    <td>{new Date(officer.createdAt).toLocaleString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination>
                        <Pagination.First onClick={() => handlePageChange(1)} disabled={page === 1} />
                        <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page === 1} />
                        {[...Array(totalPages).keys()].map(number => (
                            <Pagination.Item key={number + 1} active={number + 1 === page} onClick={() => handlePageChange(number + 1)}>
                                {number + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={page === totalPages} />
                        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={page === totalPages} />
                    </Pagination>
                </>
            )}
        </Container>
    );
};

export default OfficerList;