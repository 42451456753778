import React, { useState } from 'react';
import { Container, Form, Button, Spinner } from 'react-bootstrap';

export const route = "/officer/check-in";

const OfficerCheckInForm = () => {
  const defaultOfficerState = {
    name: '',
    badgeNumber: '',
    department: 'Wildwood Police',
    pierAssignment: 'Mariner\'s Pier/Raging Waters (Schellenger Ave)'
  };

  const [officer, setOfficer] = useState(defaultOfficerState);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setOfficer({ ...officer, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/officers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(officer),
      });

      if (response.ok) {
        alert('Check-In Successful!');
      } else {
        throw new Error('Failed to submit the form');
      }
    } catch (err) {
      console.error(err);
      alert(`Check-In Failed! Error: ${err.message}`);
    } finally {
      setOfficer(defaultOfficerState); // Reset form to default state
      setIsSubmitting(false); // Enable the button again
    }
  };

  return (
    <Container>
      <h1 className='mt-4'>Officer Check-In</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={officer.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formBadgeNumber">
          <Form.Label>Badge Number</Form.Label>
          <Form.Control
            type="text"
            name="badgeNumber"
            value={officer.badgeNumber}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formDepartment">
          <Form.Label>Department</Form.Label>
          <Form.Control
            as="select"
            name="department"
            value={officer.department}
            onChange={handleChange}
          >
            <option>Wildwood Police</option>
            <option>North Wildwood Police</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formPierAssignment">
          <Form.Label>Pier Assignment</Form.Label>
          <Form.Control
            as="select"
            name="pierAssignment"
            value={officer.pierAssignment}
            onChange={handleChange}
          >
            <option>Mariner's Pier/Raging Waters (Schellenger Ave)</option>
            <option>Surfside Pier/Ocean Oasis (26th St.)</option>
            <option>Adventure Pier (Spencer Ave.)</option>
          </Form.Control>
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3" disabled={isSubmitting}>
          {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Check-In'}
        </Button>
      </Form>
    </Container>
  );
};

export default OfficerCheckInForm;