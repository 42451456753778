import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../../CSS/Directory.module.css'; // Import the CSS module

export const route = "/mimo";
export const role = "MIMO";

const MIMODirectory = () => {
  const pages = [
    { name: 'MIMO Data', path: '/mimo/dashboard' },
    { name: 'MIMO Transactions', path: '/mimo/transactions' },
    { name: 'MIMO User Summary', path: '/mimo/user-summary' },
    { name: 'MIMO Register Summary', path: '/mimo/register-summary' },
  ];

  return (
    <div className={`container mt-5`}>
      <h1 className="text-center mb-5">MIMO Directory</h1>
      <div className="row justify-content-center">
        {pages.map((page, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className={`card shadow-sm ${styles.card}`}>
              <div className="card-body">
                <h5 className="card-title">{page.name}</h5>
                <Link to={page.path} className={`btn btn-primary w-100 ${styles.button}`}>
                  Go to {page.name}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MIMODirectory;