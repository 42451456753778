import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isSameDay, isThisWeek, isThisMonth, subDays, subMonths } from 'date-fns';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';

export const route = "/it/spare/list";
export const role = "IT";

function SpareDeploymentList() {
    const [spares, setSpares] = useState([]);
    const [groupedSpares, setGroupedSpares] = useState({});
    const [statusFilter, setStatusFilter] = useState('All');
    const [dateFilter, setDateFilter] = useState('All');
    const [customDate, setCustomDate] = useState({ start: '', end: '' });
    const navigate = useNavigate();

    const fetchSpareDeployments = useCallback(async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/spare/deployments/`);
        const data = await response.json();
        setSpares(data);
        groupSparesByStatus(data);
    }, []);

    useEffect(() => {
        fetchSpareDeployments();
    }, [fetchSpareDeployments]);

    const groupSparesByStatus = (spares) => {
        const grouped = spares.reduce((acc, spare) => {
            const spareStatus = spare.restoreDate ? 'Returned' : 'In Use';
            if (!acc[spareStatus]) {
                acc[spareStatus] = [];
            }
            acc[spareStatus].push(spare);
            return acc;
        }, {});
        setGroupedSpares(grouped);
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this spare deployment?");
        if (confirmDelete) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/spare/deployments/${id}/`, {
                method: 'DELETE'
            });
            if (response.ok) {
                fetchSpareDeployments(); // Refresh the list after deletion
            } else {
                alert('Failed to delete the spare deployment');
            }
        }
    };

    const filterByDate = (date) => {
        const now = new Date();
        switch (dateFilter) {
            case 'Today':
                return isSameDay(date, now);
            case 'Yesterday':
                return isSameDay(date, subDays(now, 1));
            case 'Current Week':
                return isThisWeek(date, { weekStartsOn: 1 });
            case 'Current Month':
                return isThisMonth(date);
            case 'Last 3 Days':
                return date >= subDays(now, 3);
            case 'Last 7 Days':
                return date >= subDays(now, 7);
            case 'Last 15 Days':
                return date >= subDays(now, 15);
            case 'Last 30 Days':
                return date >= subDays(now, 30);
            case 'Last 3 Months':
                return date >= subMonths(now, 3);
            case 'Custom':
                if (customDate.start && customDate.end) {
                    const startDate = new Date(customDate.start);
                    const endDate = new Date(customDate.end);
                    return date >= startDate && date <= endDate;
                }
                return true;
            default:
                return true;
        }
    };

    const filteredSpares = spares.filter(spare => {
        const statusMatch = statusFilter === 'All' || (statusFilter === 'In Use' && !spare.restoreDate) || (statusFilter === 'Returned' && spare.restoreDate);
        const dateMatch = filterByDate(new Date(spare.updatedAt));
        return statusMatch && dateMatch;
    });

    const sortedInUseSpares = groupedSpares['In Use']?.sort((a, b) => new Date(b.swapDate) - new Date(a.swapDate)) || [];
    const sortedReturnedSpares = groupedSpares['Returned']?.sort((a, b) => new Date(b.restoreDate) - new Date(a.restoreDate)) || [];

    return (
        <div className="container mt-5">
            <h1>Spare Deployments</h1>
            <Button onClick={() => navigate('/it/spare/create')} className="btn btn-primary mt-3 mb-3">Create +</Button>
            <Card className="mb-4">
                <Card.Body>
                    <Form>
                        <Row className="mb-3">
                            <Col md={4}>
                                <Form.Group controlId="statusFilter">
                                    <Form.Label>Status Filter</Form.Label>
                                    <Form.Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                                        <option value="All">All</option>
                                        <option value="In Use">In Use</option>
                                        <option value="Returned">Returned</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="dateFilter">
                                    <Form.Label>Date Filter</Form.Label>
                                    <Form.Select value={dateFilter} onChange={(e) => setDateFilter(e.target.value)}>
                                        <option value="All">All</option>
                                        <option value="Today">Today</option>
                                        <option value="Yesterday">Yesterday</option>
                                        <option value="Current Week">Current Week</option>
                                        <option value="Current Month">Current Month</option>
                                        <option value="Last 3 Days">Last 3 Days</option>
                                        <option value="Last 7 Days">Last 7 Days</option>
                                        <option value="Last 15 Days">Last 15 Days</option>
                                        <option value="Last 30 Days">Last 30 Days</option>
                                        <option value="Last 3 Months">Last 3 Months</option>
                                        <option value="Custom">Custom</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            {dateFilter === 'Custom' && (
                                <>
                                    <Col md={4}>
                                        <Form.Group controlId="customStartDate">
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control type="date" value={customDate.start} onChange={(e) => setCustomDate({ ...customDate, start: e.target.value })} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="customEndDate">
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control type="date" value={customDate.end} onChange={(e) => setCustomDate({ ...customDate, end: e.target.value })} />
                                        </Form.Group>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            {['In Use', 'Returned'].map((spareStatus, idx) => (
                <div className="mt-3" key={idx}>
                    <h2>{spareStatus}</h2>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Original Reader</th>
                                <th>Spare Reader</th>
                                <th>Swap Date</th>
                                <th>Restore Date</th>
                                <th>Reason</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(spareStatus === 'In Use' ? sortedInUseSpares : sortedReturnedSpares)
                                .filter(spare => filteredSpares.includes(spare))
                                .map((spare, index) => (
                                    <tr key={spare._id}>
                                        <td>{index + 1}</td>
                                        <td>{spare.originalReader.name}</td>
                                        <td>{spare.spareReader.name}</td>
                                        <td>{new Date(spare.swapDate).toLocaleString()}</td>
                                        <td>{spare.restoreDate ? new Date(spare.restoreDate).toLocaleString() : '---'}</td>
                                        <td>{spare.reason}</td>
                                        <td>
                                            <span className={`badge ${spare.restoreDate ? 'bg-success' : 'bg-warning'}`}>
                                                {spare.restoreDate ? 'Returned' : 'In Use'}
                                            </span>
                                        </td>
                                        <td>
                                            <Button variant="primary" onClick={() => navigate(`/it/spare/edit/${spare._id}`)} className="me-2">Edit</Button>
                                            <Button variant="danger" onClick={() => handleDelete(spare._id)}>Delete</Button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );
}

export default SpareDeploymentList;