import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../CSS/Homepage.css';
import Card from '../components/Card';
import cardData from '../Config/Homepage/cardData.json';

export const route = "/";

const Homepage = () => {
  const [iconMap, setIconMap] = useState({});

  useEffect(() => {
    const loadIcons = async () => {
      const icons = {};
      for (const section of cardData) {
        for (const card of section.cards) {
          if (!icons[card.icon]) {
            icons[card.icon] = (await import(`react-icons/fa`))[card.icon];
          }
        }
      }
      setIconMap(icons);
    };

    loadIcons();
  }, []);

  return (
    <div className="homepage container mt-5">
      <div className="text-center">
        <h1 className="mb-4">Welcome to Park Pulse</h1>
        <p className="lead mb-5">
          Park Pulse is your go-to platform for managing and monitoring various devices and systems within the park. Select your department to get started.
        </p>
      </div>

      {cardData.map((section, sectionIndex) => (
        <div key={sectionIndex} className="mb-5">
          <h2 className="mb-4">{section.section}</h2>
          <div className="row text-center justify-content-center"> {/* Added justify-content-center */}
            {section.cards.map((card, cardIndex) => (
              <Card
                key={cardIndex}
                title={<>{iconMap[card.icon] && React.createElement(iconMap[card.icon])} {card.title}</>}
                text={card.text}
                link={card.link}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Homepage;