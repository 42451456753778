import React, { useState, useEffect, useCallback } from 'react';
import {
    Container,
    Card,
    Spinner,
    Form,
    Accordion,
    Pagination,
} from 'react-bootstrap';

export const route = '/admin/reports';
export const role = 'ITAdmin';

function AdminReports() {
    const [reports, setReports] = useState([]);
    const [filteredReports, setFilteredReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [usernameSearchTerm, setUsernameSearchTerm] = useState('');
    const [contentSearchTerm, setContentSearchTerm] = useState('');
    const [dateFilter, setDateFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const reportsPerPage = 5;

    const fetchReports = useCallback(async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/admin/reports?role=ITAdmin`,
            );
            const data = await response.json();
            setReports(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching admin reports:', error);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchReports();
    }, [fetchReports]);

    const filterReports = useCallback((usernameTerm, contentTerm, date) => {
        let filtered = reports;

        if (usernameTerm) {
            filtered = filtered.filter(
                (report) =>
                    report.username &&
                    report.username.toLowerCase().includes(usernameTerm)
            );
        }

        if (contentTerm) {
            filtered = filtered.filter(
                (report) =>
                    (report.content &&
                        report.content.toLowerCase().includes(contentTerm)) ||
                    (report.generatedSummary &&
                        report.generatedSummary.toLowerCase().includes(contentTerm))
            );
        }

        if (date) {
            filtered = filtered.filter((report) => {
                const reportDate = new Date(report.reportDate)
                    .toISOString()
                    .split('T')[0];
                return reportDate === date;
            });
        }

        setFilteredReports(filtered);
    }, [reports]);

    useEffect(() => {
        filterReports(usernameSearchTerm, contentSearchTerm, dateFilter);
        setCurrentPage(1); // Reset to first page on filter change
    }, [reports, usernameSearchTerm, contentSearchTerm, dateFilter, filterReports]);

    const handleUsernameSearchChange = (e) => {
        const term = e.target.value.toLowerCase();
        setUsernameSearchTerm(term);
    };

    const handleContentSearchChange = (e) => {
        const term = e.target.value.toLowerCase();
        setContentSearchTerm(term);
    };

    const handleDateChange = (e) => {
        const date = e.target.value;
        setDateFilter(date);
    };

    if (loading) {
        return (
            <Spinner
                animation="border"
                variant="primary"
                className="d-block mx-auto my-5"
            />
        );
    }

    // Group reports by date
    const groupReportsByDate = (reports) => {
        return reports.reduce((groups, report) => {
            const date = new Date(report.reportDate).toLocaleDateString();
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(report);
            return groups;
        }, {});
    };

    const groupedReports = groupReportsByDate(filteredReports);
    const dates = Object.keys(groupedReports).sort(
        (a, b) => new Date(b) - new Date(a)
    );

    // Pagination logic
    const indexOfLastDate = currentPage * reportsPerPage;
    const indexOfFirstDate = indexOfLastDate - reportsPerPage;
    const currentDates = dates.slice(indexOfFirstDate, indexOfLastDate);

    return (
        <Container className="my-5">
            <Card className="shadow-sm">
                <Card.Header>
                    <h4>Employee Reports</h4>
                </Card.Header>
                <Card.Body>
                    {/* [Search forms] */}
                    {currentDates.length === 0 ? (
                        <p>No reports available.</p>
                    ) : (
                        <>
                            <Accordion defaultActiveKey="0">
                                {currentDates.map((date, idx) => (
                                    <Accordion.Item eventKey={idx.toString()} key={date}>
                                        <Accordion.Header>{date}</Accordion.Header>
                                        <Accordion.Body>
                                            {groupedReports[date].map((report) => (
                                                <Card className="shadow-sm mb-3" key={report._id}>
                                                    <Card.Header>
                                                        <h4>{report.username}</h4>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Card.Text>{report.generatedSummary}</Card.Text>
                                                        {report.tasks && report.tasks.length > 0 && (
                                                            <>
                                                                <strong>Tasks:</strong>
                                                                <ul>
                                                                    {report.tasks.map((task, index) => (
                                                                        <li key={task._id || index}>
                                                                            {task.description}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </>
                                                        )}
                                                    </Card.Body>
                                                </Card>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                            {/* [Pagination controls] */}
                        </>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
}

export default AdminReports;