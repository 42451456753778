import React from 'react';
import "../CSS/App.css"

function Footer() {
    return (
        <footer className="text-center text-lg-start text-white" style={{ background: "#3e86e6", width: '100%' }}>
            <div className="container p-4">
                <div className="row">
                    <div className="col-lg-12 col-md-12 mb-4 mb-md-0">
                        <p className="text-center">
                            © {new Date().getFullYear()} Morey's Piers. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;