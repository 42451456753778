import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../CSS/Directory.module.css'; // Import the CSS module

export const route = '/agilysys';
export const role = 'Agilysys';

const AgilysysDirectory = () => {
  const pages = [
    { name: 'Associate Pass Usage', path: '/agilysys/assc' },
  ];

  return (
    <div className={`container ${styles.directoryContainer} mt-5`}>
      <h1 className="text-center mb-5">Agilysys Directory</h1>
      <div className="row justify-content-center"> {/* Center the single card */}
        {pages.map((page, index) => (
          <div key={index} className="col-md-6 col-lg-4 mb-4"> {/* Adjust width for smaller content */}
            <div className={`card shadow-sm ${styles.card}`}>
              <div className="card-body">
                <h5 className="card-title">{page.name}</h5>
                <Link to={page.path} className={`btn btn-primary w-100 ${styles.button}`}>
                  Go to {page.name}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgilysysDirectory;