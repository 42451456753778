import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const route = "/it/device/configure/:deviceId?";
export const role = "IT";

function ConfigureTicketReader() {
    const { deviceId } = useParams();
    const [device, setDevice] = useState(null);
    const [ip, setIp] = useState('');
    const [isOnline, setIsOnline] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchDeviceDetails = async () => {
            if (deviceId) {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/${deviceId}/`);
                const data = await response.json();
                if (response.ok) {
                    setDevice(data);
                } else {
                    console.error('Failed to fetch device details');
                }
                setLoading(false);
            }
        };

        fetchDeviceDetails();
    }, [deviceId]);

    const handlePing = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/ip/${ip}`);
            const data = await response.json();
            if (response.ok) {
                setIsOnline(data.isOnline);
                setError(null);
            } else {
                setIsOnline(false);
                setError('Failed to ping device');
            }
        } catch (err) {
            console.error('Failed to ping device', err);
            setIsOnline(false);
            setError('Failed to ping device');
        } finally {
            setLoading(false);
        }
    };

    const renderIframes = (targetIp) => (
        <div className="row">
            <div className="col-md-6 mb-3">
                <h5>Game Settings</h5>
                <iframe
                    src={`${process.env.REACT_APP_API_URL}/proxy/${targetIp}/gen.zhtml`}
                    style={{ width: '100%', height: '500px', border: 'none' }}
                    title="Game Settings"
                ></iframe>
            </div>
            <div className="col-md-6 mb-3">
                <h5>Game Maintenance</h5>
                <iframe
                    src={`${process.env.REACT_APP_API_URL}/proxy/${targetIp}/GameMain.zhtml`}
                    style={{ width: '100%', height: '500px', border: 'none' }}
                    title="Game Maintenance"
                ></iframe>
            </div>
            <div className="col-md-6 mb-3">
                <h5>Network Settings</h5>
                <iframe
                    src={`${process.env.REACT_APP_API_URL}/proxy/${targetIp}/eth.zhtml`}
                    style={{ width: '100%', height: '500px', border: 'none' }}
                    title="Network Settings"
                ></iframe>
            </div>
            <div className="col-md-6 mb-3">
                <h5>SG3 Configuration</h5>
                <iframe
                    src={`${process.env.REACT_APP_API_URL}/proxy/${targetIp}/sg3.zhtml`}
                    style={{ width: '100%', height: '500px', border: 'none' }}
                    title="SG3 Configuration"
                ></iframe>
            </div>
        </div>
    );

    if (!deviceId && !ip) {
        return (
            <div className="container mb-5 mt-5">
                <h2>Configure Ticket Reader</h2>
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Enter Device IP</h5>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter IP address"
                            value={ip}
                            onChange={(e) => setIp(e.target.value)}
                        />
                        <button onClick={handlePing} className="btn btn-primary mt-3">Ping Device</button>
                        {loading && <div>Loading...</div>}
                        {error && <div className="alert alert-danger mt-3">{error}</div>}
                    </div>
                </div>
                {isOnline && renderIframes(ip)}
            </div>
        );
    }

    if (!device && deviceId) return <div>Loading...</div>;

    return (
        <div className="container mb-5 mt-5">
            <h2>Configure Ticket Reader</h2>
            {deviceId && device && (
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">{device.friendlyName}</h5>
                        <p className="card-text"><strong>Location:</strong> {device.pier}</p>
                        <p className="card-text"><strong>IP:</strong> {device.ip}</p>
                    </div>
                </div>
            )}
            {renderIframes(deviceId ? device.ip : ip)}
        </div>
    );
}

export default ConfigureTicketReader;