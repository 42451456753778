import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Spinner, Alert } from 'react-bootstrap';

export const route = "/it/device/ping";
export const role = "IT";

const PingDevice = () => {
    const [ipAddress, setIpAddress] = useState('');
    const [pingResult, setPingResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handlePing = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/ip/${ipAddress}`);
            const data = await response.json();
            setPingResult(data);
        } catch (err) {
            setError('Failed to ping device');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <h1 className="mt-4">Ping Device</h1>
            <Form onSubmit={handlePing}>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="ipAddress">
                            <Form.Label>IP Address</Form.Label>
                            <Form.Control
                                type="text"
                                value={ipAddress}
                                onChange={(e) => setIpAddress(e.target.value)}
                                placeholder="Enter IP Address"
                            />
                        </Form.Group>
                    </Col>
                    <Col className="d-flex align-items-end">
                        <Button type="submit" variant="primary">
                            Ping
                        </Button>
                    </Col>
                </Row>
            </Form>
            {loading && <Spinner animation="border" variant="primary" />}
            {error && <Alert variant="danger">{error}</Alert>}
            {pingResult && !loading && (
                <Alert variant={pingResult.isOnline ? 'success' : 'danger'}>
                    {pingResult.isOnline ? 'Device is online' : 'Device is offline'}
                </Alert>
            )}
        </Container>
    );
};

export default PingDevice;