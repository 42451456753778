import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Button, Modal, Form, Table, Alert, Card } from 'react-bootstrap';
import axios from 'axios';
import { FaEdit, FaArrowUp } from 'react-icons/fa'; // Import icons

export const route = "/it/device/winterization";
export const role = "IT";

const Winterization = () => {
  const [devices, setDevices] = useState({});
  const [activeTab, setActiveTab] = useState('Mariner\'s Pier');
  const [showModal, setShowModal] = useState(false);
  const [currentDevice, setCurrentDevice] = useState(null);
  const [formData, setFormData] = useState({
    scanOk: '',
    lightsOk: '',
    beepOk: '',
    displayOk: '',
    fanOk: '',
    overlayCondition: '',
    lightCapCondition: '',
    storageLocation: '',
    notes: '',
  });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/winterization`)
      .then(response => setDevices(response.data))
      .catch(error => console.error(error));
  }, []);

  const handleTabSelect = (key) => setActiveTab(key);
  const handleButtonClick = (field, value) => setFormData({ ...formData, [field]: value });
  const handleInputChange = (event) => setFormData({ ...formData, [event.target.name]: event.target.value });
  const handleOpenModal = (device) => {
    setCurrentDevice(device);
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);
  const handleSave = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/api/winterization/${currentDevice._id}`, formData)
      .then(response => {
        setDevices(prev => ({
          ...prev,
          [currentDevice.pier]: prev[currentDevice.pier].map(dev => dev._id === response.data._id ? response.data : dev)
        }));
        handleCloseModal();
      })
      .catch(error => console.error(error));
  };

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <div className="container mt-4">
      {/* Title and Header Section */}
      <header className="mb-4 text-center">
        <h2>Winterization Checklist</h2>
        <p className="text-muted">Manage and update the winterization status of all ticket reader devices.</p>
      </header>

      {/* Card Layout for Tabs */}
      <Card>
        <Card.Header style={{ backgroundColor: 'transparent', color: 'inherit' }}>
          <Tabs 
            activeKey={activeTab} 
            onSelect={handleTabSelect} 
            id="winterization-tabs" 
            className="mb-3"
            variant="pills" // Change variant to 'pills' to remove blue background
          >
            {Object.keys(devices).map(pier => (
              <Tab eventKey={pier} title={pier} key={pier}>
                {/* Check for empty data and display an alert */}
                {devices[pier].length === 0 ? (
                  <Alert variant="info">No devices found for {pier}.</Alert>
                ) : (
                  <div className="table-responsive">
                    <Table striped bordered hover className="mt-3">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Scan OK</th>
                          <th>Lights OK</th>
                          <th>Beep OK</th>
                          <th>Display OK</th>
                          <th>Fan OK</th>
                          <th>Overlay Condition</th>
                          <th>Light Cap Condition</th>
                          <th>Storage Location</th>
                          <th>Notes</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {devices[pier].map((device, index) => (
                          <tr key={device._id}>
                            <td>{index + 1}</td>
                            <td>{device.name}</td>
                            <td>
                              <span className={`badge ${device.winterization?.scanOk ? 'bg-success' : 'bg-danger'}`}>
                                {device.winterization?.scanOk ? 'Yes' : 'No'}
                              </span>
                            </td>
                            <td>
                              <span className={`badge ${device.winterization?.lightsOk ? 'bg-success' : 'bg-danger'}`}>
                                {device.winterization?.lightsOk ? 'Yes' : 'No'}
                              </span>
                              {!device.winterization?.lightsOk && device.winterization?.lightDetails && (
                                <div>(Issue: {device.winterization.lightDetails})</div>
                              )}
                            </td>
                            <td>
                              <span className={`badge ${device.winterization?.beepOk ? 'bg-success' : 'bg-danger'}`}>
                                {device.winterization?.beepOk ? 'Yes' : 'No'}
                              </span>
                            </td>
                            <td>
                              <span className={`badge ${device.winterization?.displayOk ? 'bg-success' : 'bg-danger'}`}>
                                {device.winterization?.displayOk ? 'Yes' : 'No'}
                              </span>
                            </td>
                            <td>
                              <span className={`badge ${device.winterization?.fanOk ? 'bg-success' : 'bg-danger'}`}>
                                {device.winterization?.fanOk ? 'Yes' : 'No'}
                              </span>
                            </td>
                            <td>
                              <span className={`badge ${device.winterization?.overlayCondition === 'Good' ? 'bg-success' : device.winterization?.overlayCondition === 'Okay' ? 'bg-warning' : 'bg-danger'}`}>
                                {device.winterization?.overlayCondition}
                              </span>
                            </td>
                            <td>
                              <span className={`badge ${device.winterization?.lightCapCondition === 'Good' ? 'bg-success' : device.winterization?.lightCapCondition === 'Okay' ? 'bg-warning' : 'bg-danger'}`}>
                                {device.winterization?.lightCapCondition}
                              </span>
                            </td>
                            <td>{device.winterization?.storageLocation || 'Not Set'}</td>
                            <td>{device.winterization?.notes || 'None'}</td>
                            <td>
                              <Button variant="link" onClick={() => handleOpenModal(device)}> {/* Use 'link' variant to remove background */}
                                <FaEdit /> Edit
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Tab>
            ))}
          </Tabs>
        </Card.Header>
      </Card>

      {/* Back to Top Button */}
      <div className="d-flex justify-content-end mt-3">
        <Button variant="outline-secondary" onClick={scrollToTop}> {/* Use 'outline-secondary' for subtle look */}
          <FaArrowUp /> Back to Top
        </Button>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Winterization Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Buttons for boolean fields */}
            <Form.Group>
              <Form.Label>Scan OK:</Form.Label>
              <div>
                <Button variant={formData.scanOk === true ? "success" : "outline-success"} onClick={() => handleButtonClick('scanOk', true)}>Yes</Button>
                <Button variant={formData.scanOk === false ? "danger" : "outline-danger"} onClick={() => handleButtonClick('scanOk', false)}>No</Button>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Both Lights OK:</Form.Label>
              <div>
                <Button variant={formData.lightsOk === true ? "success" : "outline-success"} onClick={() => handleButtonClick('lightsOk', true)}>Yes</Button>
                <Button variant={formData.lightsOk === false ? "danger" : "outline-danger"} onClick={() => handleButtonClick('lightsOk', false)}>No</Button>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Beep OK:</Form.Label>
              <div>
                <Button variant={formData.beepOk === true ? "success" : "outline-success"} onClick={() => handleButtonClick('beepOk', true)}>Yes</Button>
                <Button variant={formData.beepOk === false ? "danger" : "outline-danger"} onClick={() => handleButtonClick('beepOk', false)}>No</Button>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Display OK:</Form.Label>
              <div>
                <Button variant={formData.displayOk === true ? "success" : "outline-success"} onClick={() => handleButtonClick('displayOk', true)}>Yes</Button>
                <Button variant={formData.displayOk === false ? "danger" : "outline-danger"} onClick={() => handleButtonClick('displayOk', false)}>No</Button>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Fan OK:</Form.Label>
              <div>
                <Button variant={formData.fanOk === true ? "success" : "outline-success"} onClick={() => handleButtonClick('fanOk', true)}>Yes</Button>
                <Button variant={formData.fanOk === false ? "danger" : "outline-danger"} onClick={() => handleButtonClick('fanOk', false)}>No</Button>
              </div>
            </Form.Group>

            {/* Buttons for condition fields */}
            <Form.Group>
              <Form.Label>Overlay Condition:</Form.Label>
              <div>
                <Button variant={formData.overlayCondition === "Good" ? "success" : "outline-success"} onClick={() => handleButtonClick('overlayCondition', 'Good')}>Good</Button>
                <Button variant={formData.overlayCondition === "Okay" ? "warning" : "outline-warning"} onClick={() => handleButtonClick('overlayCondition', 'Okay')}>Okay</Button>
                <Button variant={formData.overlayCondition === "Poor" ? "danger" : "outline-danger"} onClick={() => handleButtonClick('overlayCondition', 'Poor')}>Poor</Button>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Light Cap Condition:</Form.Label>
              <div>
                <Button variant={formData.lightCapCondition === "Good" ? "success" : "outline-success"} onClick={() => handleButtonClick('lightCapCondition', 'Good')}>Good</Button>
                <Button variant={formData.lightCapCondition === "Okay" ? "warning" : "outline-warning"} onClick={() => handleButtonClick('lightCapCondition', 'Okay')}>Okay</Button>
                <Button variant={formData.lightCapCondition === "Poor" ? "danger" : "outline-danger"} onClick={() => handleButtonClick('lightCapCondition', 'Poor')}>Poor</Button>
              </div>
            </Form.Group>

            {/* Inputs for open-ended fields */}
            <Form.Group>
              <Form.Label>Storage Location:</Form.Label>
              <Form.Control
                type="text"
                name="storageLocation"
                value={formData.storageLocation}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Notes:</Form.Label>
              <Form.Control
                as="textarea"
                name="notes"
                value={formData.notes}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          <Button variant="primary" onClick={handleSave}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Winterization;